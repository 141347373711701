import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import Container from '../layout/Container';
import Button from '../components/Button';
import { GatsbyImage } from 'gatsby-plugin-image';

const LeftImageCardContainer = styled(Container)`
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
  padding: ${theme.sizes.lvl6} ${theme.sizes.lvl6};
  `}
  ${({ theme }) => theme.breakpoint('desktop')`
  padding: ${theme.sizes.lvl6} ${theme.sizes.lvl6};
  `}
    ${({ theme }) => theme.breakpoint('medium')`
  padding: ${theme.sizes.lvl8} ${theme.sizes.lvl8};
  `}
`;

const Inner = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    flex-direction: row;
  align-items: flex-start;

  `}
`;

const GiftImage = styled(Img)`
  margin: 0 0 ${({ theme }) => theme.sizes.lvl2} 0;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    width: 300px;
  `}
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    margin: 0 ${({ theme }) => theme.sizes.lvl4}
    ${({ theme }) => theme.sizes.lvl2} 0;

  `}
`;

const GatsbyGiftImage = styled(GatsbyImage)`
  margin: 0 0 ${({ theme }) => theme.sizes.lvl2} 0;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    width: 300px;
  `}
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    margin: 0 ${({ theme }) => theme.sizes.lvl4}
    ${({ theme }) => theme.sizes.lvl2} 0;

  `}
`;

const ContentContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const ExtraTitle = styled.h2`
  && {
    text-align: center;
    color: ${({ theme }) => theme.colours.white};
    margin-bottom: ${({ theme }) => theme.sizes.lvl4};
  }
`;

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.sizes.lvl3};
`;
const StyledLink = styled.a`
  text-decoration: none;
`;

const LeftImageCard = ({
  id,
  title,
  subtitle,
  imageFluid,
  gatsbyImage,
  children,
  className,
  extraTitle,
  buttonLink,
  buttonLabel,
}) => {
  return (
    <LeftImageCardContainer className={className} id={id}>
      {extraTitle !== null && (
        <ExtraTitle className="h3">{extraTitle}</ExtraTitle>
      )}
      <Inner>
        {gatsbyImage && <GatsbyGiftImage image={gatsbyImage} alt="Meditation" />}
        {imageFluid && <GiftImage fluid={imageFluid} />}
        <ContentContainer>
          <h3 className="strong h4">{title}</h3>
          <h4 className="h4">{subtitle}</h4>
          {children}
          {buttonLink !== '' && (
            <StyledLink href={buttonLink} target="_BLANK">
              <StyledButton>{buttonLabel}</StyledButton>
            </StyledLink>
          )}
        </ContentContainer>
      </Inner>
    </LeftImageCardContainer>
  );
};

LeftImageCard.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  imageFluid: PropTypes.object,
  children: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  extraTitle: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonLabel: PropTypes.string,
};

LeftImageCard.defaultProps = {
  id: '',
  className: '',
  imageFluid: null,
  children: null,
  title: '',
  subtitle: '',
  extraTitle: null,
  buttonLink: '',
  buttonLabel: 'Watch now',
};

export default LeftImageCard;
