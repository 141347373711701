import React from 'react';
import styled from 'styled-components';

import Layout from '../layout/Layout';
import Seo from '../components/Seo';
import ScrollTarget from '../components/ScrollTarget';
import LandingSection from '../sections/landing';
import LandingRegistration from '../sections/landing/landingRegistration';
import LandingStickyRegistration from '../sections/landing/landingStickyRegistration';
import LandingGifts from '../sections/landing/landingGifts';
import ConferenceContentSection from '../sections/conferenceContent';
import WhatIsSuperConferenceSection from '../sections/whatIsSuperConference';
import HomepageExpertsSection from '../sections/homepageExperts';
import ForThoseSufferingSection from '../sections/forThoseSuffering';
import RegistrationRepeatedSection from '../sections/registrationRepeated';
import GiftsSection from '../sections/gifts';
import YourHostSection from '../sections/yourHost';
import SponsorsSection from '../sections/sponsors';
import LandingTrailerSection from '../sections/landing/landingTrailer';

const LandingRegistrationMobileOnly = styled(LandingRegistration)`
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
  display: none;
  `};
`;

const IndexPage = ({ location }) => {
  // Affiliate Id is returned from infusionsoft's redirect. We store it here so we can post it when creating firebase registration later.
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.affiliate) {
    localStorage.setItem('superconferenceAffiliate', params.affiliate);
  }

  return (
    <Layout>
      <Seo title="Home" />

      <LandingSection />

      <LandingRegistrationMobileOnly />

      <LandingTrailerSection />

      <ScrollTarget id="signup" />

      <LandingRegistration />

      <LandingGifts />

      <LandingStickyRegistration />

      <ConferenceContentSection />

      <WhatIsSuperConferenceSection />

      <HomepageExpertsSection />

      <ForThoseSufferingSection />

      <GiftsSection />

      <RegistrationRepeatedSection />

      <YourHostSection showHeader />

      <SponsorsSection />
    </Layout>
  );
};

export default IndexPage;
