import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import Layout from '../layout/Layout';
import Seo from '../components/Seo';
import LandingSection from '../sections/landing';
import LandingRegistration from '../sections/landing/landingRegistration';
import ConferenceContentSection from '../sections/conferenceContent';
import WhatIsSuperConferenceSection from '../sections/whatIsSuperConference';
import HomepageExpertsSection from '../sections/homepageExperts';
import ForThoseSufferingSection from '../sections/forThoseSuffering';
import GiftsSection from '../sections/gifts';
import YourHostSection from '../sections/yourHost';
import SponsorsSection from '../sections/sponsors';
import Meditations from '../sections/meditations';
import SpeakersCarousel from '../components/SpeakerCarousel';

const MeditationPage = (props) => {
  const { location, data: { allAirtable: { edges: meditations }, airtableHosts, speakers } } = props;

  const mappedSpeakers =
    speakers?.edges
      ?.map((edge) => edge?.node)
      .filter(({ data }) => !!data.Speaker)
      .sort(
        (speakerA, speakerB) =>
          speakerA.data.ExpertOrder - speakerB.data.ExpertOrder
      )
      .map(({ data }) => ({
        id: data.Speaker[0].id,
        name: data.Speaker[0].data.Name,
        tagline: data.Name,
        image: data.Speaker[0].data.Avatar.localFiles[0],
        slug: data.Slug,
        contentList: data.Contentlist,
        category: data.Category,
      })) || [];

  // Affiliate Id is returned from infusionsoft's redirect. We store it here so we can post it when creating firebase registration later.
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.affiliate) {
    localStorage.setItem('superconferenceAffiliate', params.affiliate);
  }

  return (
    <Layout>
      <Seo title="Meditation" />

      <LandingSection
        title={`10 Free Guided Meditations`}
        subtitle={(<>
          They are <strong>FREE</strong> and available to <strong>DOWNLOAD NOW</strong>
        </>)}
        formSubmitLabel={`Send Me The Meditations`}
        hideFormTooltip
        hideVideo
        hideNav
      />

      <LandingRegistrationMobileOnly />

      <Background className="text-base lg:text-xl md:text-center flex flex-col items-center py-10">
        <div className="max-w-[960px] px-8 space-y-5 md:space-y-0">
          <p>Do you suffer from a <u>chronic illness</u> or have ongoing <u>health issues?</u></p>
          <p>Would you like to experience the benefits of <strong>meditation</strong>, but don't know <strong>where to start?</strong></p>
        </div>
      </Background>

      <BackgroundBlue className="text-white text-2xl lg:text-3xl py-4">
        <div className="max-w-[960px] text-center flex flex-col items-center mx-auto">
          <span className="border-2 border-white rounded-full">
            <GatsbyImage imgClassName="rounded-full" className="rounded-full border-2 border-transparent box-border" image={getImage(airtableHosts.data.Avatar.localFiles[0])} alt="Alex Howard" />
          </span>
          <div className="mt-4">
            <p>Join <strong>Alex Howard</strong>, creator of Therapeutic Coaching<sup className="text-xs">TM</sup></p>
            <p>for 10 FREE guided meditations</p>
          </div>
        </div>
      </BackgroundBlue>

      <Background>
        <Meditations meditations={meditations} />
      </Background>

      <LandingRegistration
        title="Get 10 Free Meditations"
        subtitle="Available To Download Now"
        formSubmitLabel="Send Me The Meditations"
        formTooltipLabel={(<div>
          Also Includes <br />
          <span>Free Ticket</span>
        </div>)}
      />

      <Background className="px-2 text-center flex flex-col items-center space-y-4 py-4">
        <StaticImage src="../images/ticket_icon.png" />
        <p className="text-lg">Your 10 Free Guided Meditations <u>also includes:</u></p>
        <div className="space-y-2">
          <p className="font-bold text-3xl sm:text-4xl md:text-5xl">FREE TICKET To The Upcomming</p>
          <p className="font-bold text-3xl sm:text-4xl md:text-5xl">Trauma Super Conference</p>
        </div>
        <p className="text-lg">The conference features <strong>70+ SPEAKERS</strong>, including:</p>
        <SpeakersCarousel speakers={mappedSpeakers} />
      </Background>

      <ConferenceContentSection />

      <WhatIsSuperConferenceSection />

      <HomepageExpertsSection />

      <ForThoseSufferingSection />

      <GiftsSection />

      <LandingRegistration
        className="mt-16"
        title="Get 10 Free Meditations"
        subtitle="Available To Download Now"
        formSubmitLabel="Send Me The Meditations"
        formTooltipLabel={(<div>
          Also Includes <br />
          <span>Free Ticket</span>
        </div>)}
      />

      <YourHostSection showHeader />

      <SponsorsSection />
    </Layout >
  );
};

export const query = graphql`
{
  allAirtable(
    filter: {table: {eq: "Session"}, data: {Category: {eq: "Meditation"}}}
    sort: {fields: data___Day___data___Date, order: ASC}
  ) {
    edges {
      node {
        id
        data {
          Slug
          Name
          Tagline {
            childMarkdownRemark {
              html
            }
          }
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 320, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    }
  }
  airtableHosts(data: {Name: {eq: "Alex Howard"}}) {
    data {
      Avatar {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 160, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
  speakers: allAirtable(
    filter: {table: {eq: "Session"}, data: {BonusSession: {ne: true}, Speaker: {elemMatch: {data: {MainHost: {ne: true}, Host: {ne: true}}}}}}
  ) {
    edges {
      node {
        data {
          Name
          DateOrder
          BonusSession
          Contentlist {
            childMarkdownRemark {
              html
            }
          }
          Speaker {
            id
            data {
              Name
              WebsiteUri
              ExpertOrder
              Host
              MainHost
              Avatar {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 400)
                  }
                }
              }
            }
          }
        }
        id
      }
    }
  }
}
`;

const LandingRegistrationMobileOnly = styled(LandingRegistration)`
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
  display: none;
  `};
`;

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const BackgroundBlue = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundAlt};
`;

export default MeditationPage;
