module.exports = {
  apiKey: 'AIzaSyARYelGcurHeh6L-K1BwsqzN_qUDqQpj0Y',
  apiUrl: 'https://us-central1-trauma-super-conference.cloudfunctions.net',
  authDomain: 'trauma-super-conference.firebaseapp.com',
  projectId: 'trauma-super-conference',
  storageBucket: 'trauma-super-conference.appspot.com',
  messagingSenderId: '790236053407',
  appId: '1:790236053407:web:02b2e09d76622d45bdc7aa',
  measurementId: 'G-FWH33RGB6S',
};
// module.exports = {
//   apiKey: 'AIzaSyDxXHNODfvJkL8cS1z2nc6vfy2hGMWelJE',
//   apiUrl: 'https://us-central1-development-super-conference.cloudfunctions.net',
//   authDomain: 'development-super-conference.firebaseapp.com',
//   projectId: 'development-super-conference',
//   storageBucket: 'development-super-conference.appspot.com',
//   messagingSenderId: '411510101803',
//   appId: '1:411510101803:web:a8296cdfc491f0349e176e',
//   measurementId: 'G-FWH33RGB6S',
// };
