import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Container from '../layout/Container';
import Button from './Button';
import PaymentPayPal from './PaymentPayPal';
import { useSiteMetaContext } from '../context/SiteMetaContext';

const PaymentSummaryContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    flex-direction: row;
  `}
`;

const FormTitle = styled.h5`
  color: ${({ theme }) => theme.colours.textDisabled};
`;

const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.font.sizes.xs};
`;

const Column = styled.div`
  flex: 1;
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  border: 1px solid ${({ theme }) => theme.colours.lightGray};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
`;

const SummaryInner = styled.div`
  width: 100%;
  max-width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${({ theme }) => theme.sizes.lvl2};
`;
const SummaryInnerBottom = styled(SummaryInner)`
  border-top: 1px solid ${({ theme }) => theme.colours.lightGray};
`;

const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const PurchasedItem = styled.p`
  margin: ${({ theme }) => theme.sizes.lvl1} 0px;

  font-size: ${({ theme }) => theme.font.sizes.sm};
  font-weight: 400;
`;

const PurchasedItemPrice = styled.p`
  margin: ${({ theme }) => theme.sizes.lvl1} 0px;

  font-size: ${({ theme }) => theme.font.sizes.xs};
  font-weight: 400;
`;

const Line = styled.p`
  && {
    font-size: ${({ theme }) => theme.font.sizes.xs};
    ${({ theme }) => theme.breakpoint('medium')`
    font-size: ${({ theme }) => theme.font.sizes.sm};
  `}
  }
`;

const Total = styled.p`
  margin: ${({ theme }) => theme.sizes.lvl1} 0px;
  font-weight: 400;
`;

const PaymentSummary = ({
  address,
  selectedPlan,
  selectCard,
  onPayPalSuccess,
  searchParams,
}) => {
  const { title } = useSiteMetaContext();

  return (
    <PaymentSummaryContainer>
      {address && address.line1 && (
        <Column>
          <FormTitle className="h5">YOUR SHIPPING ADDRESS</FormTitle>
          {address.line1 && <Line className="p">{address.line1}</Line>}
          {address.line2 && <Line className="p">{address.line2}</Line>}
          {address.city && <Line className="p">{address.city}</Line>}
          {address.state && <Line className="p">{address.state}</Line>}
          {address.zip && <Line className="p">{address.zip}</Line>}
          {address.country && <Line className="p">{address.country}</Line>}
          <Line>
            <Link to={`/checkout/address?${searchParams.toString()}`}>
              Change my shipping address
            </Link>
          </Line>
        </Column>
      )}
      <Column>
        <SummaryWrapper>
          <SummaryInner>
            <h2 className="h3">ORDER SUMMARY</h2>

            <SummaryRow>
              <PurchasedItem>
                {title}
                <br /> {selectedPlan.name}
              </PurchasedItem>
              <PurchasedItemPrice>${selectedPlan.price}.00</PurchasedItemPrice>
            </SummaryRow>
            <StyledLink to="/sales">Change plan</StyledLink>
          </SummaryInner>
          <SummaryInnerBottom>
            <SummaryRow>
              <Total className="p">
                <b>Subtotal</b>
              </Total>
              <Total>${selectedPlan.price}.00</Total>
            </SummaryRow>
          </SummaryInnerBottom>
        </SummaryWrapper>

        <Button level="secondary" fluid={true} onClick={selectCard}>
          Pay By Credit/Debit Card
        </Button>
        <br />
        <PaymentPayPal onSuccess={onPayPalSuccess} />
      </Column>
    </PaymentSummaryContainer>
  );
};

export default PaymentSummary;
