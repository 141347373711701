import React from 'react';
import styled from 'styled-components';

const ErrorMessage = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.colours.signal.error};
  font-size: ${({ theme }) => theme.font.sizes.xs};
  margin-top: ${({ theme }) => theme.sizes.lvl0};
`;

const Error = ({ error }) => {
  if (!error) {
    return null;
  }

  return <ErrorMessage>{error}</ErrorMessage>;
};

export default Error;
