import React from 'react';
import styled from 'styled-components';

import Container from '../layout/Container';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.primary};
`;

const Inner = styled(Container)`
  color: ${({ theme }) => theme.colours.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledTitle = styled.h1`
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    br {
      display: none;
    }
  `}
`;

const WhatIsSuperConferenceSection = () => {
  return (
    <Background>
      <Inner>
        <StyledTitle className="h2">
          What makes this a <br />
          <span>Super conference?</span>
        </StyledTitle>
        <p>
          We are not only bringing together{' '}
          <strong>
            in-depth interviews with 70+ of the world’s top experts in trauma
          </strong>
          , but we are also offering <strong>extra resources</strong>, including
          tools, techniques and practices you can use to help you become aware
          of your own trauma, understand the impact it has on your daily life,
          and begin to start the healing process.
        </p>
      </Inner>
    </Background>
  );
};

export default WhatIsSuperConferenceSection;
