import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';
import { CountryDropdown } from 'react-country-region-selector';

import Container from '../layout/Container';
import Button from './Button';
import TextInput from './TextInput';
import Error from './Error';
import { useUserContext } from '../context/UserContext';

const Disclaimer = styled.p`
  line-height: 150%;
  font-size: ${({ theme }) => theme.font.sizes.xxxs};
  text-align: left;
  padding: ${({ theme }) => theme.sizes.lvl3} 0px;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
  font-size: ${({ theme }) => theme.font.sizes.xxs};
`}
`;

const StyledCountryDropdown = styled(CountryDropdown)`
  background-color: ${({ theme }) => theme.colours.white};
  height: ${({ theme }) => theme.sizes.formControlHeight};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colours.lightGray};
  padding-left: ${({ theme }) => theme.sizes.lvl2};
  font-size: ${({ theme }) => theme.font.sizes.xs};

  ${({ theme }) => theme.breakpoint('medium')`
  font-size: ${({ theme }) => theme.font.sizes.md};
  `}
`;

const FormTitle = styled.h3`
  color: ${({ theme }) => theme.colours.darkGray};
  font-family: ${({ theme }) => theme.font.families.title};
  margin: 0;
  padding: 0;
  font-weight: 400;
`;

const FormSubtitle = styled.h5`
  color: ${({ theme }) => theme.colours.primary};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
      width: 50%;
  `}
`;

const FormControl = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.sizes.lvl3};
  flex-grow: 1;
`;

const schema = yup.object().shape({
  line1: yup
    .string()
    .required('Address line 1 is required')
    .trim()
    .label('Line 1'),
  line2: yup.string().trim(),
  city: yup.string().required('City is required').trim().label('City'),
  state: yup.string().required('State is required').trim().label('State'),
  zip: yup
    .string()
    .required('Zip/Postal Code is required')
    .trim()
    .label('Zip/Postal Code'),
});

const ShippingAddressForm = ({ onSuccess }) => {
  const { updateDb, user } = useUserContext();
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
    defaultValues: user,
  });
  const [country, setCountry] = useState(
    user && user.country ? user.country : undefined
  );
  const [countryError, setCountryError] = useState('');

  const onSubmit = async (data) => {
    if (!country) {
      setCountryError('Country is required');
      return;
    }
    setCountryError('');

    try {
      await updateDb({
        address: { ...data, country },
      });
      onSuccess();
    } catch (error) {
      setError('form', { message: error.message });
    }
  };

  const onChangeCountry = (country) => {
    setCountry(country);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormTitle className="h3">Shipping address</FormTitle>
        <FormSubtitle className="h5">
          Please add the shipping address for your USB below.
        </FormSubtitle>
        <FormControl>
          <TextInput
            fluid={true}
            name="line1"
            placeholder="Street Address"
            ref={register}
            aria-label="Line 1"
            error={errors.line1?.message}
            border="1px solid #AAA"
          />
        </FormControl>
        <FormControl>
          <TextInput
            fluid={true}
            name="line2"
            placeholder="Street Address Line 2 (optional)"
            ref={register}
            aria-label="Line 2"
            error={errors.line2?.message}
            border="1px solid #AAA"
          />
        </FormControl>
        <FormControl>
          <TextInput
            fluid={true}
            name="city"
            placeholder="City"
            ref={register}
            aria-label="City"
            error={errors.city?.message}
            border="1px solid #AAA"
          />
        </FormControl>
        <FormControl>
          <TextInput
            fluid={true}
            width="100%"
            name="state"
            placeholder="State"
            ref={register}
            aria-label="State"
            error={errors.state?.message}
            border="1px solid #AAA"
          />
        </FormControl>

        <FormControl>
          <TextInput
            fluid={true}
            name="zip"
            placeholder="Zip/Postal Code"
            ref={register}
            aria-label="Zip/Postal Code"
            error={errors.zip?.message}
            border="1px solid #AAA"
          />
        </FormControl>
        <StyledCountryDropdown
          value={country}
          onChange={onChangeCountry}
          valueType="short"
        />
        <Error error={countryError} />

        <Disclaimer>
          Please note, your USB will be shipped after the event and could take
          around 30 days to arrive.
        </Disclaimer>
        <Button fluid={true} type="submit">
          Next
        </Button>

        {errors && errors.form && <Error error={errors.form?.message} />}
      </Form>
    </Container>
  );
};

export default ShippingAddressForm;
