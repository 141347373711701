import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import Container from '../layout/Container';
import Img from 'gatsby-image';

const StyledBackgroundImage = styled(BackgroundImage)`
  background-attachment: fixed;
`;

const BackgroundSection = ({ className, children, data }) => {
  const backgroundFluidImageStack = [
    data.desktop.childImageSharp.fluid,
    `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1))`,
  ].reverse();

  return (
    <StyledBackgroundImage
      Tag="section"
      className={className}
      fluid={backgroundFluidImageStack}
      backgroundColor={`#040e18`}
    >
      {children}
    </StyledBackgroundImage>
  );
};

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  z-index: 1;
`;
const InnerCol = styled.div`
  color: ${({ theme }) => theme.colours.white};
  padding: ${({ theme }) => theme.sizes.lvl2} 0px
    ${({ theme }) => theme.sizes.lvl4} ${({ theme }) => theme.sizes.lvl2};
  margin-bottom: ${({ theme }) => theme.sizes.lvl8};
  text-align: center;
  ${({ theme }) => theme.breakpoint('phoneLarge')`
    margin-bottom: 8rem;
  `}
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    margin-bottom: 0px;
    width: 50%;
    text-align: left;
    padding-bottom: ${theme.sizes.lvl8};
  `}
`;

const PictureWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const PictureOverlap = styled.div`
  position: absolute;
  max-width: 520px;
  width: 85%;
  left: 50%;
  bottom: -${({ theme }) => theme.sizes.sizeByFactor(12)};
  border: 3px solid ${({ theme }) => theme.colours.white};

  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    width: 40%;
    right:  0;
    left: auto;
    transform: none;
    -webkit-transform: none;
  `};
`;

const ListContainer = styled.ul`
  text-align: left;
  margin-bottom: ${({ theme }) => theme.sizes.lvl4};
  li {
    margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  }
`;

const ForThoseSufferingSection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "flower_hands_bg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        suffer: file(relativePath: { eq: "suffer.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <StyledBackgroundSection data={data}>
      <Container>
        <InnerCol>
          <h1 className="h2">
            <span>For those suffering:</span>
            <br />
            we also have
          </h1>
          <ListContainer>
            <li>Yoga videos</li>
            <li>Guided meditations</li>
            <li>Filmed therapy sessions to bring healing theories to life</li>
          </ListContainer>
          <p>
            Don't miss this rare opportunity to learn from <strong>70+</strong>{' '}
            of the world's most respected doctors, psychologists, scientists,
            healers, and researchers.
          </p>
        </InnerCol>
        <PictureWrapper>
          <PictureOverlap>
            <Img fluid={data.suffer.childImageSharp.fluid} />
          </PictureOverlap>
        </PictureWrapper>
      </Container>
    </StyledBackgroundSection>
  );
};

export default ForThoseSufferingSection;
