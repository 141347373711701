import { isPast, format } from 'date-fns';

const formatReplayDateString = (startTime, endTime, days) => {
  const conferenceReplayStartTime = new Date(
    startTime.year,
    startTime.month,
    startTime.day,
    startTime.hour
  );
  const conferenceReplayEndTime = new Date(
    endTime.year,
    endTime.month,
    endTime.day,
    endTime.hour
  );

  return `Replay weekend ${format(
    conferenceReplayStartTime,
    `${days ? 'EEEE' : ''} MMMM do`
  )} - ${format(
    conferenceReplayEndTime,
    `${days ? 'EEEE' : ''} MMMM do yyyy`
  )}`;
};

const formatOriginalDateString = (startTime, endTime, days) => {
  const conferenceStartTime = new Date(
    startTime.year,
    startTime.month,
    startTime.day,
    startTime.hour
  );
  const conferenceEndTime = new Date(
    endTime.year,
    endTime.month,
    endTime.day,
    endTime.hour
  );

  return `${format(conferenceStartTime, `${days ? 'EEEE' : ''} MMMM do`)} - ${format(
    conferenceEndTime,
    `${days ? 'EEEE' : ''} MMMM do  yyyy`
  )}`;
};

const hasConferenceEnded = (endTime) =>
  isPast(new Date(endTime.year, endTime.month, endTime.day, endTime.hour));

export const formatConferenceDateString = (
  startTime,
  endTime,
  replayStartTime,
  replayEndTime,
  days
) =>
  hasConferenceEnded(endTime) && replayStartTime && replayEndTime
    ? formatReplayDateString(replayStartTime, replayEndTime, days)
    : formatOriginalDateString(startTime, endTime, days);
