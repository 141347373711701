import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import { HostContent, Name } from './Host';

const HostImage = styled.div`
  height: 400px;
  width: 100%;
  margin: 0 ${({ theme }) => theme.sizes.lvl2};
  overflow: hidden;

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    margin: 0 ${theme.sizes.lvl4}
      ${theme.sizes.lvl2} 0;
    width: 300px;
    `}
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    margin: 0 ${theme.sizes.lvl2}
      ${theme.sizes.lvl2};
    width: 100%;
    height: 500px;
  `}
  ${({ theme }) => theme.breakpoint('medium')`
    height: 700px;
  `}
  .gatsby-image-wrapper {
    height: 100%;
    img {
      object-position: top !important;
    }
  }
`;

const HostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.sizes.lvl2} 0;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    flex-direction: row;
    align-items: flex-start;
  `}
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    padding: ${({ theme }) => theme.sizes.lvl2};
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    flex-direction: column;
    align-items: center;
  `}
`;

const OtherHost = ({ name, html, fluidAvatar, className, website }) => {
  return (
    <HostContainer className={className}>
      <HostImage>
        <Img fluid={fluidAvatar} />
      </HostImage>
      <HostContent>
        <Name>{name}</Name>
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
        {website && (
          <p className="p">
            <a href={website} target="_blank" rel="noreferrer">
              Visit website
            </a>
          </p>
        )}
      </HostContent>
    </HostContainer>
  );
};

OtherHost.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  fluidAvatar: PropTypes.any,
  html: PropTypes.string,
};

OtherHost.defaultProps = {
  className: '',
  fluidAvatar: null,
  html: '',
  children: null,
};

export default OtherHost;
