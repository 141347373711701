import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { getYear, format } from 'date-fns';

import { useSiteMetaContext } from '../context/SiteMetaContext';
import Container from '../layout/Container';
import Button from '../components/Button';

const ThankYou = styled.h1`
  color: ${({ theme }) => theme.colours.secondary};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
`;

const Content = styled.p`
  width: 100%;
  text-align: center;
  max-width: 100%;
  padding-bottom: ${({ theme }) => theme.sizes.lvl2};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 0 auto;
`;

const Center = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PaymentSuccess = () => {
  const {
    conferenceStartTime,
    hasConferenceStarted,
    hasConferenceEnded,
    replayWeekendEnabled,
    title,
  } = useSiteMetaContext();

  const conferenceAvailableDate = format(conferenceStartTime, 'do MMMM yyyy');

  return (
    <Center>
      <ThankYou className="h2">
        Thank you for purchasing the
        <br />
        <strong>
          {title}{' '}
          {replayWeekendEnabled && hasConferenceEnded
            ? 'Replay'
            : getYear(conferenceStartTime)}
        </strong>
        !
      </ThankYou>
      <Content>
        {hasConferenceStarted
          ? 'Your lifetime access is now available. You can view the content by heading to the agenda page below.'
          : `Your lifetime access will be available from ${conferenceAvailableDate}. You can find out more about the speakers by heading to the agenda page below.`}
      </Content>
      <StyledLink to="/agenda">
        <Button level="primary">See Conference Agenda</Button>
      </StyledLink>
    </Center>
  );
};

export default PaymentSuccess;
