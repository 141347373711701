import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import IncludedTickPath from '../../images/included_white_tick.svg';
import ExternalLink from '../../images/external_link.inline.svg';
import { useSiteMetaContext } from '../../context/SiteMetaContext';
import theme from '../../utils/theme';
import Button from '../Button';
import PaidContent from '../PaidContent';

const SpeakerContainer = styled.article`
  text-align: center;
  margin-top: ${({ theme }) => theme.sizes.lvl6};

  background: ${({ colour }) => colour};
  color: ${({ theme }) => theme.colours.white};
  padding: ${({ theme }) => theme.sizes.lvl4};
  border-radius: ${({ theme }) => theme.sizes.lvl2};
`;

const Inner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Tag = styled.div`
  margin: 0px;
  min-height: ${({ theme }) => theme.sizes.lvl10};
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.7;
`;
const Category = styled(Tag)`
  text-transform: uppercase;
  font-weight: bold;
  min-height: ${({ theme }) => theme.sizes.lvl2};
  font-size: ${({ theme }) => theme.font.sizes.xs};
`;

const Name = styled.h1`
  font-weight: 700;
  min-height: 3rem;
  margin: 0 0 ${({ theme }) => theme.sizes.lvl1} 0;
`;

const AvatarWrapper = styled.div`
  height: ${({ theme }) => theme.sizes.lvl20};
  width: ${({ theme }) => theme.sizes.lvl20};
  margin: -${({ theme }) => theme.sizes.lvl10} auto ${({ theme }) =>
      theme.sizes.lvl2} auto;

  position: relative;
`;

const AvatarContainer = styled.div`
  position: relative;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  img {
    object-position: top !important;
  }
`;

const PositionedButton = styled(Button)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  &:last-child {
    margin-bottom: 0;
  }
`;

const ContentListContainer = styled.div`
  text-align: left;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.sizes.sm};
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  padding-top: ${({ theme }) => theme.sizes.lvl3};
  border-top: solid 1px rgba(255, 255, 255, 0.36);

  ul {
    padding: 0;
    li {
      list-style: none;
      font-size: ${({ theme }) => theme.font.sizes.sm};
      background: url(${IncludedTickPath}) no-repeat left center;
      padding: ${({ theme }) =>
        `${theme.sizes.lvl0} ${theme.sizes.lvl1} ${theme.sizes.lvl0} ${theme.sizes.lvl5}`};
    }
  }
`;
const ContentListTitle = styled.h4`
  && {
    color: rgba(255, 255, 255, 0.5);
    font-size: ${({ theme }) => theme.font.sizes.lg};
    font-weight: 400;
  }
`;
const ContentList = styled.div`
  margin-top: ${({ theme }) => theme.sizes.lvl2};
`;

const ButtonText = styled.span`
  font-size: ${({ theme }) => theme.font.sizes.sm};
`;
const ButtonLink = styled.a`
  text-decoration: none;
`;
const ButtonGatsbyLink = styled(Link)`
  text-decoration: none;
`;

const ExternalUrlLink = styled(ButtonLink)`
  position: absolute;
  bottom: -${({ theme }) => theme.sizes.lvl0};
  right: -${({ theme }) => theme.sizes.lvl0};
`;
const ExternalUrlButton = styled(Button)`
  width: ${({ theme }) => theme.sizes.lvl6};
  height: ${({ theme }) => theme.sizes.lvl6};
  border: ${({ theme }) => theme.sizes.lvl0} solid
    ${({ theme }) => theme.colours.secondary};
  border-radius: 100%;
  padding: 0;
`;
const ActionButtonPositioner = styled.div`
  margin-top: auto;
`;

const SpeakerWithButtons = ({
  speaker,
  className,
  colour,
  buttonColour,
  hideVideoButton,
  hideWebsiteButton,
  hideBuyButton,
  date,
}) => {
  const { hasConferenceStarted, isReplayConferenceLive } = useSiteMetaContext();
  const avatar = speaker.Avatar?.localFiles[0];

  return (
    <SpeakerContainer className={className} colour={colour}>
      <Inner>
        {avatar && avatar.childImageSharp && (
          <AvatarWrapper>
            <AvatarContainer>
              <Img fluid={avatar.childImageSharp.fluid} />
            </AvatarContainer>
            {speaker.WebsiteUri && !hideWebsiteButton && (
              <ExternalUrlLink
                href={speaker.WebsiteUri}
                rel="noopener"
                title={`Link to ${speaker.Name}'s website`}
                target="_blank"
              >
                <ExternalUrlButton
                  fluid
                  variant="filledDarker"
                  to={speaker.WebsiteUri}
                  level="white"
                >
                  <ExternalLink />
                </ExternalUrlButton>
              </ExternalUrlLink>
            )}
          </AvatarWrapper>
        )}
        <Name className="h4">{speaker.Name}</Name>
        <Tag>{speaker.Tagline}</Tag>
        {speaker.Contentlist && (
          <ContentListContainer>
            <ContentListTitle className="h4">
              What you'll learn
            </ContentListTitle>
            {speaker.BonusSession && <Category>{speaker.Category}</Category>}
            <ContentList
              dangerouslySetInnerHTML={{
                __html: speaker.Contentlist?.childMarkdownRemark.html,
              }}
            />
          </ContentListContainer>
        )}
        <ActionButtonPositioner>
          <PaidContent
            freeDate={date}
            alwaysFree={isReplayConferenceLive}
            notPaid={
              hideBuyButton ? (
                <></>
              ) : (
                <ButtonGatsbyLink to="/sales" title={`Link to sales page`}>
                  <PositionedButton
                    fluid
                    level={buttonColour}
                    variant="filledDarker"
                  >
                    <ButtonText>Buy now</ButtonText>
                  </PositionedButton>
                </ButtonGatsbyLink>
              )
            }
          >
            {speaker.VideoSlug && hasConferenceStarted && !hideVideoButton ? (
              <ButtonLink
                href={'/session/' + speaker.VideoSlug}
                title={`Link to ${speaker.Name}'s content`}
                target="_blank"
              >
                <PositionedButton
                  fluid
                  level={buttonColour}
                  variant="filledDarker"
                >
                  <ButtonText>Watch now</ButtonText>
                </PositionedButton>
              </ButtonLink>
            ) : (
              <></>
            )}
          </PaidContent>
        </ActionButtonPositioner>
      </Inner>
    </SpeakerContainer>
  );
};

SpeakerWithButtons.propTypes = {
  className: PropTypes.string,
  speaker: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    Tagline: PropTypes.string,
    Avatar: PropTypes.any,
    WebsiteUri: PropTypes.string,
    VideoSlug: PropTypes.string,
  }),
  colour: PropTypes.string,
  buttonColour: PropTypes.string,
  hideVideoButton: PropTypes.bool,
  hideWebsiteButton: PropTypes.bool,
  hideBuyButton: PropTypes.bool,
  date: PropTypes.string,
};

SpeakerWithButtons.defaultProps = {
  className: '',
  speaker: {},
  colour: theme.colours.secondary,
  buttonColour: 'secondary',
  hideVideoButton: false,
  hideWebsiteButton: false,
  hideBuyButton: false,
  date: null,
};

export default SpeakerWithButtons;
