import React from 'react';
import ReactDOM from 'react-dom';

import { useUserContext } from '../context/UserContext';

const PaymentPayPal = ({ onSuccess }) => {
  const { createPaypalOrder, confirmPaypalOrder } = useUserContext();

  const PayPalButton = window.paypal.Buttons.driver('react', {
    React,
    ReactDOM,
  });

  const completeOrder = async ({ orderID }) => {
    try {
      await confirmPaypalOrder(orderID);
      onSuccess();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <PayPalButton createOrder={createPaypalOrder} onApprove={completeOrder} />
  );
};

export default PaymentPayPal;
