import React from 'react';

import AgendaExpertsSection from '../sections/agendaExperts';
import Seo from '../components/Seo';
import YourHostSection from '../sections/yourHost';
import BuyNowCta from '../sections/buyNowCta';
import Sponsors from '../sections/sponsors';
import RegisteredLayout from '../layout/RegisteredLayout';

const AgendaPage = ({ location }) => (
  <RegisteredLayout location={location}>
    <Seo title="Agenda" />
    <AgendaExpertsSection />
    <YourHostSection />
    <BuyNowCta />
    <Sponsors />
  </RegisteredLayout>
);

export default AgendaPage;
