import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Container from '../layout/Container';
import ExpertsAndTitle from '../components/ExpertsAndTitle';
import { dayMeta } from '../constants/dayMeta';

const ExpertsBackground = styled.div`
  background-color: ${({ $hasBackground, theme }) =>
    $hasBackground ? theme.colours.backgroundPaper : '#fff'};
`;

const BonusExpertsAndTitle = styled(ExpertsAndTitle)`
  margin-top: ${({ theme }) => theme.sizes.lvl4};
  ${({ theme }) => theme.breakpoint('desktop')`
    margin-top: 0;
  `}
`;

const AgendaExpertsSection = () => {
  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "Day" } }
        sort: { fields: data___Date }
      ) {
        nodes {
          id
          data {
            Name
            ShortName
            LongName
            Date
            Session {
              id
              data {
                Name
                Slug
                DateOrder
                Category
                BonusSession
                Contentlist {
                  childMarkdownRemark {
                    html
                  }
                }
                Speaker {
                  id
                  data {
                    Name
                    WebsiteUri
                    Avatar {
                      localFiles {
                        childImageSharp {
                          gatsbyImageData(
                            width: 600
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                          )
                          fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    }
  `);

  const dates = allAirtable?.nodes;

  return (
    <>
      {dates
        .sort(
          (dateA, dateB) =>
            new Date(dateA.data.Date) - new Date(dateB.data.Date)
        )
        .map(({ data, id }, index) => {
          const bonusSpeakers = data.Session.map(
            ({ data: sessionData }) => sessionData
          )
            .filter(({ BonusSession }) => BonusSession)
            .sort(
              (sessionA, sessionB) => sessionA.DateOrder - sessionB.DateOrder
            )
            .map((data) => ({
              id: data.Speaker[0].id,
              data: {
                ...data.Speaker[0].data,
                Tagline: data.Name,
                Category: data.Category,
                Contentlist: data.Contentlist,
                VideoSlug: data.Slug,
                BonusSession: data.BonusSession,
                WebsiteUri: undefined,
              },
            }));
          const normalSpeakers = data.Session.map(
            ({ data: sessionData }) => sessionData
          )
            .filter(({ BonusSession }) => !BonusSession)
            .sort(
              (sessionA, sessionB) => sessionA.DateOrder - sessionB.DateOrder
            )
            .map((data) => ({
              id: data.Speaker[0].id,
              data: {
                ...data.Speaker[0].data,
                Tagline: data.Name,
                Category: data.Category,
                Contentlist: data.Contentlist,
                VideoSlug: data.Slug,
              },
            }));
          return (
            <ExpertsBackground key={`experts_${id}`} $hasBackground={index % 2}>
              <Container>
                <ExpertsAndTitle
                  title={`"${data.ShortName}"`}
                  description={data.LongName}
                  speakers={normalSpeakers}
                  colour={dayMeta[data.Date].colour}
                  buttonColour={dayMeta[data.Date].buttonColour}
                  date={data.Date}
                  day={index + 1}
                />
                {bonusSpeakers && bonusSpeakers.length > 0 && (
                  <BonusExpertsAndTitle
                    title="Bonus Sessions"
                    speakers={bonusSpeakers}
                    colour={dayMeta[data.Date].colour}
                    buttonColour={dayMeta[data.Date].buttonColour}
                    date={data.Date}
                  />
                )}
              </Container>
            </ExpertsBackground>
          );
        })}
    </>
  );
};

export default AgendaExpertsSection;
