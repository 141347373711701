/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import './src/global.css'
import React from 'react';

// noinspection ES6UnusedImports
import * as normalize from 'normalize.css';

import { UserContextProvider } from './src/context/UserContext';
import { SiteMetaContextProvider } from './src/context/SiteMetaContext';
import { captureAffiliateIds } from './src/services/affiliate';

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
  const leadSources = captureAffiliateIds();
  console.log('Lead Source:')
  console.dir(leadSources)
};

export const wrapRootElement = ({ element }) => (
  <UserContextProvider>
    <SiteMetaContextProvider>{element}</SiteMetaContextProvider>
  </UserContextProvider>
);
