import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Container from '../layout/Container';

const Background = styled.div`
  background-color: ${({ theme, $isWhite }) =>
    $isWhite === true ? theme.colours.white : theme.colours.backgroundPaper};
`;

const Wrapper = styled(Container)`
  color: ${({ theme }) => theme.colours.black};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme.breakpoint('desktop')`
    flex-direction: row;
  `}
`;

const ImageWrapper = styled.div`
  padding: 0px;
  width: 75%;
  ${({ theme }) => theme.breakpoint('desktop')`
   width: 65%;
  `};
`;

const ContentWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${({ theme }) => theme.breakpoint('desktop')`
   width:50%;
   padding-left: ${theme.sizes.lvl4};
  `};
`;

const ImageSection = styled(ContentWrapper)`
  align-items: center;
  justify-content: center;
  padding: 0px;
`;

const Separator = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.colours.bulletPointColor};
  margin-top: ${({ theme }) => theme.sizes.lvl6};
  margin-bottom: ${({ theme }) => theme.sizes.lvl6};
  display: flex;

  ${({ theme }) => theme.breakpoint('desktop')`
  margin-top: ${({ top, theme }) => (!top ? theme.sizes.lvl6 : `0px`)};
  margin-bottom: ${({ top, theme }) => (top ? theme.sizes.lvl6 : `0px`)};
  `};
`;

const ListContainer = styled.ul`
  text-align: left;
  font-weight: 100;
  li {
    margin-bottom: ${({ theme }) => theme.sizes.lvl4};
    font-size: ${({ theme }) => theme.sizes.lvl2};
    font-size: ${({ theme }) => theme.sizes.lvl2};
    &:last-child {
      margin-bottom: 0;
    }
    b {
      font-weight: 700;
    }
  }
`;

const GuaranteeBigSection = ({ isWhite }) => {
  const { img, imgpaper, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        img: file(relativePath: { eq: "guarantee_white.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imgpaper: file(relativePath: { eq: "guarantee.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const conferenceTitle = site?.siteMetadata?.title;

  return (
    <Background $isWhite={isWhite}>
      <Wrapper>
        <ImageSection>
          <ImageWrapper>
            {isWhite === true ? (
              <Img fluid={img?.childImageSharp.fluid} />
            ) : (
              <Img fluid={imgpaper?.childImageSharp.fluid} />
            )}
          </ImageWrapper>
        </ImageSection>
        <ContentWrapper>
          <Separator top={true}></Separator>

          <ListContainer>
            <li>
              <b>You're protected</b> by our No-Questions-Asked,
              <br></br>
              <b>30 Day 100% Money-Back Guarantee</b>
            </li>
            <li>
              Order the {conferenceTitle} and get{' '}
              <b>lifetime access to this life-changing event.</b>
            </li>
            <li>
              If for any reason at all you're not fully satisfied within the
              first 30 days,{' '}
              <b>
                we'll personally refund every penny you invested in your
                package.
              </b>{' '}
              You simply email us, no questions asked, it's super simple. It
              matters to us that you are happy.
            </li>
            <li>
              <b>That's how confident we are in the value of this conference</b>{' '}
              and that you will want to keep it to come back to again and again.
              Once again, no questions asked if you want your money back for any
              reason at all within the 30-days, you just email us and tell us.{' '}
              <b>You have everything to gain, nothing to lose.</b>
            </li>
          </ListContainer>

          <Separator top={false}></Separator>
        </ContentWrapper>
      </Wrapper>
    </Background>
  );
};

GuaranteeBigSection.propTypes = {
  isWhite: PropTypes.bool,
};

GuaranteeBigSection.defaultProps = {
  isWhite: true,
};

export default GuaranteeBigSection;
