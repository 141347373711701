import React, { forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Error from './Error';

const Wrapper = styled.div`
  display: ${({ $block }) => ($block ? 'block' : 'inline-block')};
  width: ${({ $fluid }) => ($fluid ? '100%' : 'auto')};
`;

const Input = styled.input`
  box-sizing: border-box;
  height: ${({ theme }) => theme.sizes.formControlHeight};
  padding: ${({ theme }) => theme.sizes.lvl3} ${({ theme }) => theme.sizes.lvl3};

  background: white;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  border: ${({ $border }) => $border};
  outline: ${({ theme, $error }) =>
    $error ? `${theme.colours.signal.error} 3px solid` : 'none'};
  width: ${({ $fluid, $block }) => ($fluid && !$block ? '100%' : 'auto')};

  ::placeholder {
    color: ${({ theme }) => theme.colours.textDisabled};
  }
  :hover,
  :active,
  :focus {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  }
  :focus {
    outline: ${({ theme, $error }) =>
      $error ? `${theme.colours.signal.error} 3px solid` : 'none'};
  }

  font-size: ${({ theme }) => theme.font.sizes.lg};
`;

const TextInput = forwardRef(
  ({ fluid, block, error, border, className, ...otherProps }, ref) => {
    return (
      <Wrapper $block={block} $fluid={fluid} className={className}>
        <Input
          $fluid={fluid}
          $error={!!error}
          $border={border}
          ref={ref}
          {...otherProps}
        />
        <Error error={error} />
      </Wrapper>
    );
  }
);

TextInput.propTypes = {
  register: PropTypes.any,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  fluid: PropTypes.bool,
  block: PropTypes.bool,
  error: PropTypes.string,
  passedRef: PropTypes.func,
  border: PropTypes.string,
};

TextInput.defaultProps = {
  onChange: null,
  disabled: false,
  placeholder: null,
  /** Makes the input 100% width */
  fluid: false,
  /** Makes the input block CSS */
  block: false,
  /** Error message and activates error state */
  error: '',
  /** Used to pass ref object to input to be used react-hook-form */
  passedRef: undefined,
  border: 'none',
};

export default TextInput;
