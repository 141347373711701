import React from 'react';
import styled from 'styled-components';

import Seo from '../components/Seo';
import RegisteredLayout from '../layout/RegisteredLayout';

const Title = styled.h3`
  text-align: center;
  padding: ${({ theme }) => theme.sizes.lvl4};
  font-family: ${({ theme }) => theme.font.families.body} !important;
`;

const ComingSoonPage = () => (
  <RegisteredLayout>
    <Seo title="Coming soon" />
    <Title>
      Transcripts will be available for download by Monday 11th October.
      <br /> Thank you for your patience.
    </Title>
  </RegisteredLayout>
);

export default ComingSoonPage;
