import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Container from '../layout/Container';
import PaidContent from '../components/PaidContent';

import { format } from 'date-fns';

const Wrapper = styled(Container)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ListContainer = styled.ul`
  text-align: left;
  li {
    margin-bottom: ${({ theme }) => theme.sizes.lvl2};
    font-size: ${({ theme }) => theme.sizes.lvl2};
    font-size: ${({ theme }) => theme.sizes.lvl2};
    a {
      text-decoration: none;
      font-weight: bold;
      color: ${({ theme }) => theme.colours.primary};
    }
  }
`;

const DownArrow = styled.span`
  color: ${({ theme }) => theme.colours.primary};
  font-size: ${({ theme }) => theme.sizes.lvl5};
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    display: none;
  `}
`;

const Copy = styled.p`
  a {
    color: ${({ theme }) => theme.colours.primary};
  }
`;

const ThankYouIntroSection = ({ context }) => {
  const {
    hasConferenceEnded,
    conferenceDateString,
    conferenceEndTime,
    conferenceStartTime,
    title,
  } = context;
  const { site = {} } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            conferenceInformation {
              replayStarttime {
                year
                month
                day
                hour
              }
              replayEndtime {
                year
                month
                day
                hour
              }
            }
          }
        }
      }
    `
  );
  const {
    replayStarttime,
    replayEndtime,
  } = site?.siteMetadata?.conferenceInformation;

  const conferenceReplayStartTime = new Date(
    replayStarttime.year,
    replayStarttime.month,
    replayStarttime.day,
    replayStarttime.hour
  );
  const conferenceReplayEndTime = new Date(
    replayEndtime.year,
    replayEndtime.month,
    replayEndtime.day,
    replayEndtime.hour
  );

  return (
    <Wrapper>
      <h1 className="h3">
        Thank you for registering for the <br />
        <span>{title}</span> <br />- use the links below to get the most from
        this page:
      </h1>
      <ListContainer>
        <li>
          <a title="Bookmark the agenda page" href="#stepBookmark">
            Bookmark
          </a>{' '}
          the Day Agenda Page
        </li>

        <li>
          <a title="Access free gifts" href="#stepGifts">
            Access
          </a>{' '}
          your free gifts
        </li>
        <PaidContent
          notPaid={<li>Take advantage of <a title="Get lifetime access" target="_blank" href="/sales">sale</a> prices to keep these talks!</li>}
        >
          <></>
        </PaidContent>
      </ListContainer>
      <Copy>
        {title} is <b>FREE</b> during the
        <b> {conferenceDateString}</b>
        . <br />
        <br />
        The talks will be available from
        <b>
          {' '}
          {conferenceReplayStartTime && conferenceStartTime
            ? format(
              hasConferenceEnded
                ? conferenceReplayStartTime
                : conferenceStartTime,
              "EEEE do MMMM HH':00 '"
            )
            : ''}{' '}
          -{' '}
          {conferenceReplayEndTime && conferenceEndTime
            ? format(
              hasConferenceEnded
                ? conferenceReplayEndTime
                : conferenceEndTime,
              "EEEE do MMMM HH':00 '"
            )
            : ''}
        </b>
        in your local time zone!
        <br />
        <br />
        You can find out more about the speakers by heading to the agenda page
        below.
      </Copy>

      <DownArrow className="material-icons header-arrow">
        keyboard_arrow_down
      </DownArrow>
    </Wrapper>
  );
};

export default ThankYouIntroSection;
