import React, { useContext } from 'react';
import { Link } from 'gatsby';
import styled, { ThemeContext, css } from 'styled-components';

import Icon from './Icon';
import { facebook, instagram, twitter } from '../images/icons';
import socialLinks from '../constants/socialLinks';
import { useUserContext } from '../context/UserContext';

const StyledFooter = styled.footer`
  position: relative;
  background-color: ${({ theme }) => theme.colours.darkGray};
  text-align: center;
  padding: ${({ theme }) => theme.sizes.lvl2};
  color: ${({ theme }) => theme.colours.lightGray};
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
`;
const FlexRowLinks = styled(FlexRow)`
  flex-direction: column;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    flex-direction: row;
  `}
`;

const IconWrap = styled.div`
  width: ${({ theme }) => theme.sizes.lvl4};
  height: ${({ theme }) => theme.sizes.lvl4};
  border-radius: ${({ theme }) => theme.sizes.lvl4};
  margin: 0 ${({ theme }) => theme.sizes.lvl2};
  background-color: ${({ theme }) => theme.colours.backgroundFooter};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Copyright = styled.p`
  font-size: ${({ theme }) => theme.font.sizes.xxxs};
  margin: 0 auto;

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
  font-size: ${({ theme }) => theme.font.sizes.xxs};
  `}
`;
const Disclaimer = styled.p`
  line-height: 150%;
  font-size: ${({ theme }) => theme.font.sizes.xxxs};
  margin: 0 auto;

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
  font-size: ${({ theme }) => theme.font.sizes.xxs};
`}
`;
const FooterLink = css`
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.colours.lightGray}!important;
  font-size: ${({ theme }) => theme.font.sizes.xxs};
  margin: 0 0 ${({ theme }) => theme.sizes.lvl1} 0;
  &:last-child {
    margin-right: 0;
  }
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    margin:  0 ${({ theme }) => theme.sizes.lvl2} 0 0;
    font-size: ${({ theme }) => theme.font.sizes.xs};
  `}
`;
const FooterAnchorLink = styled.a`
  ${FooterLink}
`;
const FooterGatsbyLink = styled(Link)`
  ${FooterLink}
`;
const ZenDeskOpener = styled.span`
  ${FooterLink}
`;

const Footer = () => {
  const currentDate = new Date();

  const theme = useContext(ThemeContext);
  const { isLoggedIn, isRegistered, signOut } = useUserContext();

  return (
    <StyledFooter>
      <FlexRow>
        <IconWrap onClick={() => window.open(socialLinks.facebook, '_blank')}>
          <Icon svg={facebook} fillColor={theme.colours.lightGray} size="sm" />
        </IconWrap>
        <IconWrap onClick={() => window.open(socialLinks.twitter, '_blank')}>
          <Icon svg={twitter} fillColor={theme.colours.lightGray} size="sm" />
        </IconWrap>
        <IconWrap onClick={() => window.open(socialLinks.instagram, '_blank')}>
          <Icon svg={instagram} fillColor={theme.colours.lightGray} size="sm" />
        </IconWrap>
      </FlexRow>
      <FlexRowLinks>
        <ZenDeskOpener
          onClick={() => {
            window.zE('webWidget', 'open');
          }}
          target="_blank"
          rel="noreferrer"
        >
          Contact us
        </ZenDeskOpener>
        {isRegistered && (
          <FooterGatsbyLink to="/agenda">Agenda</FooterGatsbyLink>
        )}
        {isLoggedIn && (
          <FooterAnchorLink onClick={signOut} href="#">
            Logout
          </FooterAnchorLink>
        )}

        <FooterAnchorLink
          onClick={signOut}
          href="https://2020.traumasuperconference.com/"
          target="_blank"
          rel="noreferrer"
        >
          Members access to 2020 recordings
        </FooterAnchorLink>

        {!isLoggedIn && <FooterGatsbyLink to="/login">Login</FooterGatsbyLink>}
      </FlexRowLinks>
      <FlexRow>
        <Copyright>
          Copyright © Conscious Life Ltd {currentDate.getFullYear()}. All rights
          reserved.
        </Copyright>
      </FlexRow>
      <FlexRow>
        <Disclaimer>
          Information on this web site is provided for informational purposes
          only. This information is not intended as a substitute for the advice
          provided by your physician or other healthcare professional or any
          information contained on or in any product label or packaging. Do not
          use the information on this web site for diagnosing or treating a
          health problem or disease, or prescribing medication or other
          treatment. Always speak with your physician or other healthcare
          professional before taking any medication or nutritional, herbal or
          homeopathic supplement, or using any treatment for a health problem.
          If you have or suspect that you have a medical problem, contact your
          health care provider promptly. Do not disregard professional medical
          advice or delay in seeking professional advice because of something
          you have read on this web site. Information provided on this web site
          and the use of any products or services purchased from our web site by
          you DOES NOT create a doctor-patient relationship between you and any
          of the physicians affiliated with our web site. Information and
          statements regarding dietary supplements have not been evaluated by
          the Food and Drug Administration and are not intended to diagnose,
          treat, cure, or prevent any disease.
        </Disclaimer>
      </FlexRow>
    </StyledFooter>
  );
};

export default Footer;
