import { createBreakpoint } from 'styled-components-breakpoint';

const breakpointObj = {
  phone: 0,
  phoneLarge: 480,
  tabletPortrait: 600,
  tabletIpadPortrait: 768,
  tabletLandscape: 960,
  desktop: 1279,
  medium: 1440,
  giant: 1920,
};

const breakpoint = createBreakpoint(breakpointObj);

const colours = {
  secondaryLight: '#F5FAFC',
  secondary: '#359FCB',
  secondaryDark: '#0476A7',

  primaryLight: '#d8c0a6',
  primary: '#F5A225',
  primaryDark: '#F17110',

  white: '#FFF',
  darkGray: '#262525',
  lightGray: '#AAA',
  black: '#000',

  gold: '#E7A440',
};

const buttonColours = {
  primary: {
    background: {
      filled: colours.primary,
      filledDarker: colours.primaryDark,
      outline: 'transparent',
    },
    text: {
      filled: colours.text,
      filledDarker: colours.white,
      outline: colours.primaryLight,
    },
    outline: {
      filled: colours.primary,
      filledDarker: colours.primaryDark,
      outline: colours.primaryLight,
    },
  },
  secondary: {
    background: {
      filled: colours.secondary,
      filledDarker: colours.secondaryDark,
      outline: 'transparent',
    },
    text: {
      filled: colours.white,
      filledDarker: colours.white,
      outline: colours.secondaryDark,
    },
    outline: {
      filled: colours.secondary,
      filledDarker: colours.secondaryDark,
      outline: colours.secondaryDark,
    },
  },
  black: {
    background: {
      filled: colours.black,
      filledDarker: colours.black,
      outline: 'transparent',
    },
    text: {
      filled: colours.white,
      filledDarker: colours.white,
      outline: colours.black,
    },
    outline: {
      filled: colours.black,
      filledDarker: colours.black,
      outline: colours.black,
    },
  },
  white: {
    background: {
      filled: colours.white,
      filledDarker: colours.white,
      outline: 'transparent',
    },
    text: {
      filled: colours.black,
      filledDarker: colours.black,
      outline: colours.white,
    },
    outline: {
      filled: colours.white,
      filledDarker: colours.white,
      outline: 'rgba(255, 255, 255, 0.4)',
    },
  },
};

const coloursMapped = {
  signal: {
    error: 'red',
    warning: 'yellow',
    success: '#09BC8A',
  },
  backgroundAlt: colours.secondary,
  backgroundPaper: '#FAF7F2',
  backgroundDarkPaper: '#ece8e9',
  backgroundFooter: '#5d493d',
  backgroundGuarantee: '#FBEEDB',
  bulletPointColor: colours.primary,
  text: 'rgba(0,0,0,0.75)',
  textDisabled: '#868686',
  textGuarantee: colours.gold,
};

const zIndexes = {
  header: 10,
  stickyRegistrationForm: 11,
  footer: 10,
  nextPrevCarousel: 2,
  normalSalesOption: 2,
  centeredSalesOption: 3,
};

const baseSize = 0.5;
const sizeByFactor = (factor) => `${factor * baseSize}rem`;
const sizesByFactor = {
  sizeByFactor,
  lvl0: sizeByFactor(0.5), // 0.5 * 0.5rem = 0.5 * 8px = 4px;
  lvl1: sizeByFactor(1), // 1 * 0.5rem = 1 * 8px = 8px;
  lvl2: sizeByFactor(2), // 2 * 0.5rem = 2 * 8px = 16px;
  lvl3: sizeByFactor(3), // 24px;
  lvl4: sizeByFactor(4), // 32px;
  lvl5: sizeByFactor(5), // 40px;
  lvl6: sizeByFactor(6), // 48px;
  lvl7: sizeByFactor(7), // 56px;
  lvl8: sizeByFactor(8), // 64px;
  lvl9: sizeByFactor(9), // 72px;
  lvl10: sizeByFactor(10), // 80px;
  lvl11: sizeByFactor(11),
  lvl12: sizeByFactor(12),
  lvl13: sizeByFactor(13),
  lvl14: sizeByFactor(14),
  lvl15: sizeByFactor(15),
  lvl16: sizeByFactor(16),
  lvl17: sizeByFactor(17),
  lvl18: sizeByFactor(18),
  lvl19: sizeByFactor(19),
  lvl20: sizeByFactor(20),
};

const icon = {
  sm: '16px',
  md: '24px',
  lg: '32px',
};

const sizes = {
  ...sizesByFactor,
  icon,
  borderRadius: '4px',
  formControlHeight: sizesByFactor.lvl8,
  formControlPaddingBottom: sizesByFactor.lvl3,
};

const font = {
  families: {
    title: 'Inter',
    body: 'Inter',
  },
  sizes: {
    xxxxs: '0.5rem',
    xxxs: '0.575rem',
    xxs: '0.625rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    xxl: '1.5rem',
    xxxl: '2rem',
    xxxxl: '3rem',
    xxxxxl: '4.5rem',
    xxxxxxl: '6rem',
  },
};

const theme = {
  breakpoints: breakpointObj,
  colours: {
    ...colours,
    ...coloursMapped,
  },
  buttonColours,
  zIndexes,
  font,
  sizes,
  breakpoint,
};

export default theme;
