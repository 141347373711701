import React from 'react';

import SalesLayout from '../layout/SalesLayout';
import Seo from '../components/Seo';
import LoginIntroSection from '../sections/loginIntro';
import LoginFormSection from '../sections/loginForm';
import LoginExtraContentSection from '../sections/loginExtraContent';

const LoginPage = () => (
  <SalesLayout>
    <Seo title="Login" />
    <LoginIntroSection />
    <LoginFormSection />
    <LoginExtraContentSection />
  </SalesLayout>
);

export default LoginPage;
