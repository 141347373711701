import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import IntroSection from '../sections/sales/salesIntro';
import Seo from '../components/Seo';
import Container from '../layout/Container';
import Layout from '../layout/Layout';
import SalesOptionSection from '../sections/sales/salesOptions';
import GuaranteeSection from '../sections/guarantee2';
import SalesTrailerSection from '../sections/sales/salesTrailer';
import SalesExpertsSection from '../sections/sales/salesExperts';
import SalesDevicesSection from '../sections/sales/salesDevices';
import SalesBenefitsSection from '../sections/sales/salesBenefits';
import YourHostSection from '../sections/yourHost';
import SponsorsSection from '../sections/sponsors';

const Background = styled.div`
  background-color: ${({ theme }) => theme.colours.secondary};
  position: relative;
  z-index: 1;
`;
const BackgroundWhite = styled.div`
  background-color: ${({ theme }) => theme.colours.white};
  position: relative;
  z-index: 1;
`;
const IntroSectionWithPadding = styled(IntroSection)`
  && {
    padding-bottom: ${({ theme }) => theme.sizes.sizeByFactor(14)};
    ${({ theme }) => theme.breakpoint('phoneLarge')`
      padding-bottom: ${({ theme }) => theme.sizes.sizeByFactor(20)};
    `}
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      padding-bottom: ${({ theme }) => theme.sizes.sizeByFactor(32)};
    `}
  }
`;
const PositionedSalesOptionSection = styled(SalesOptionSection)`
  padding-top: 0;
`;

const IPadImpressionImageWrapper = styled(Container)`
  padding-bottom: 0;
  padding-top: 0;
`;
const IPadImpressionImageContainer = styled.div`
  margin: -${({ theme }) => theme.sizes.sizeByFactor(14)} auto 0;
  ${({ theme }) => theme.breakpoint('phoneLarge')`
    margin: -${({ theme }) => theme.sizes.sizeByFactor(20)} auto 0;
  `}
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    margin: -${({ theme }) => theme.sizes.sizeByFactor(32)} auto 0;
  `}
`;

const SalesPage = () => {
  const { salesipad } = useStaticQuery(
    graphql`
      query {
        salesipad: file(relativePath: { eq: "sales_ipad_render.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  return (
    <Layout>
      <main>
        <Seo title="Sales" />

        <Background>
          <IntroSectionWithPadding />
          <IPadImpressionImageWrapper>
            <IPadImpressionImageContainer>
              <Img
                fluid={salesipad.childImageSharp.fluid}
                alt="Super conference on iPad"
              />
            </IPadImpressionImageContainer>
          </IPadImpressionImageWrapper>
          <PositionedSalesOptionSection />
        </Background>
        <SalesTrailerSection />
        <BackgroundWhite>
          <SalesDevicesSection />
          <SalesBenefitsSection />
        </BackgroundWhite>
        <Background>
          <GuaranteeSection />
          <SalesExpertsSection />
          <YourHostSection />
          <SponsorsSection />
        </Background>
      </main>
    </Layout>
  );
};

export default SalesPage;
