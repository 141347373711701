import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Badge from '../../components/Badge';
import Container from '../../layout/Container';

const Background = styled.div`
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    background-color: ${({ theme }) => theme.colours.secondary};
  `}
`;
const SalesContainer = styled(Container)`
  color: white;
  text-align: center;
  position: relative;
`;

const ImagesPositioner = styled.div`
  display: flex;
  justify-content: center;
`;
const DesktopImageContainer = styled.div`
  position: relative;
  width: 100%;
`;
const PositionedBadge = styled(Badge)`
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  position: absolute;
  display: none;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: inline;
  `}

  ${({ $top }) => ($top ? `top: ${$top};` : '')};
  ${({ $bottom }) => ($bottom ? `bottom: ${$bottom};` : '')};
  ${({ $left }) => ($left ? `left: ${$left};` : '')};
  ${({ $right }) => ($right ? `right: ${$right};` : '')};
`;

const DesktopImage = styled(Img)`
  display: none;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
  display: block;
  `}
`;

const MobileImage = styled(Img)`
  margin-left: -${({ theme }) => theme.sizes.lvl2};
  margin-right: -${({ theme }) => theme.sizes.lvl2};
  margin-bottom: -${({ theme }) => theme.sizes.lvl5};
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    margin-left: -${({ theme }) => theme.sizes.lvl3};
    margin-right: -${({ theme }) => theme.sizes.lvl3};
  `}
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
   display: none;
  `}
`;

const SalesDevicesSection = ({ className }) => {
  const { deviceTrinity, deviceTrinityMobile } = useStaticQuery(
    graphql`
      query {
        deviceTrinity: file(relativePath: { eq: "device_trinity.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        deviceTrinityMobile: file(
          relativePath: { eq: "device_trinity_mobile.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <Background className={className}>
      <SalesContainer>
        <ImagesPositioner>
          <DesktopImageContainer>
            <DesktopImage fluid={deviceTrinity?.childImageSharp.fluid} />
            <MobileImage fluid={deviceTrinityMobile?.childImageSharp.fluid} />
            <PositionedBadge icon="check" $top="10%" $right="15%">
              Digital Transcripts
            </PositionedBadge>
            <PositionedBadge icon="check" $top="30%" $right="0">
              Yoga &amp; Meditation
            </PositionedBadge>
            <PositionedBadge icon="check" $top="35%" $left="0">
              Therapy Sessions
            </PositionedBadge>
            <PositionedBadge icon="check" $bottom="20%" $left="10%">
              Audio Files
            </PositionedBadge>
          </DesktopImageContainer>
        </ImagesPositioner>
      </SalesContainer>
    </Background>
  );
};

export default SalesDevicesSection;
