import React from 'react';
import styled from 'styled-components';

// phoneLarge: 480,
//   tabletPortrait: 600,
//     tabletIpadPortrait: 768,
//       tabletLandscape: 960,
//         desktop: 1279,
//           medium: 1440,
//             giant: 1920,

const Container = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  padding: ${({ theme }) => theme.sizes.lvl1} ${({ theme }) => theme.sizes.lvl1};

  ${({ theme }) => theme.breakpoint('phoneLarge')`
    max-width: 480px;
  `}

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    max-width: 600px;
    padding: ${theme.sizes.lvl6} ${theme.sizes.lvl3};
  `}
  
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    max-width: 960px;
    padding: ${theme.sizes.lvl8} ${theme.sizes.lvl4};
  `}
  
  ${({ theme }) => theme.breakpoint('desktop')`
  max-width: 1279px;
  padding: ${theme.sizes.lvl8} ${theme.sizes.lvl6};
  `}
  
  ${({ theme }) => theme.breakpoint('medium')`
    max-width: 1440px;
    padding: ${theme.sizes.lvl8} ${theme.sizes.lvl8};
  `}
`;

const ContainerComponent = ({ children, className }) => (
  <Container className={className}>{children}</Container>
);

export default ContainerComponent;
