import React from 'react';

import SalesLayout from '../layout/SalesLayout';
import Seo from '../components/Seo';
import LoginIntroSection from '../sections/loginIntro';
import ResetPasswordFormSection from '../sections/resetPasswordForm';

const ResetPasswordPage = () => (
  <SalesLayout>
    <Seo title="Login" />
    <LoginIntroSection />
    <ResetPasswordFormSection />
  </SalesLayout>
);

export default ResetPasswordPage;
