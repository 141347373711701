import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { useSiteMetaContext } from '../context/SiteMetaContext';
import { useUserContext } from '../context/UserContext';
import Container from '../layout/Container';
import Button from './Button';
import TextInput from './TextInput';
import Loader from './Loader';

const FormWrapper = styled.div`
  background-color: ${({ theme }) => theme.colours.backgroundAlt};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.sizes.lvl5} ${({ theme }) => theme.sizes.lvl3};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
     padding: ${({ theme }) => theme.sizes.lvl5} ${({ theme }) =>
    theme.sizes.lvl8};

  `}
`;
// const LoginLink = styled.a`
//   color: ${({ theme }) => theme.colours.white};
//   text-decoration: underline;
// `;

const Disclaimer = styled.p`
  text-align: center;
  line-height: 150%;
  font-size: ${({ theme }) => theme.font.sizes.xxxs};
  margin: 0 auto;
  padding-bottom: ${({ theme }) => theme.sizes.lvl3};
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
  font-size: ${({ theme }) => theme.font.sizes.xxs};
`}
`;
const FormTitle = styled.h3`
  color: ${({ theme }) => theme.colours.darkGray};
  margin: 0;
  padding: 0 0 ${({ theme }) => theme.sizes.lvl4} 0;
  text-align: center;
  font-weight: 400;
`;

const FormSubtitle = styled.h5`
  text-align: center;
  font-weight: 400;
  color: ${({ theme }) => theme.colours.white};
  font-size: ${({ theme }) => theme.font.sizes.md};
  font-family: ${({ theme }) => theme.font.families.title};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    width: 50%;
  `}
`;

const FormControl = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.lvl3};
  flex-grow: 0;
  width: 100%;
`;

const ErrorText = styled.p`
  font-size: ${({ theme }) => theme.sizes.lvl2};
  color: ${({ theme }) => theme.colours.signal.error};
  margin: 0 ${({ theme }) => theme.sizes.lvl1};
`;

const PositionedLoader = styled(Loader)`
  margin: 0 auto;
`;

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .trim()
    .label('First name'),
  lastName: yup
    .string()
    .required('Last name is required')
    .trim()
    .label('Last name'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Must be at least 6 characters long')
    .label('Your password'),
  email: yup
    .string()
    .required('Email is required')
    .email('Must be a valid email')
    .trim()
    .label('Your email'),
});

const SignupForm = () => {
  const { title } = useSiteMetaContext();
  const [loading, setLoading] = useState(false);
  const { signUp, user } = useUserContext();
  const defaultValues = {
    firstName: user ? user.firstName : '',
    lastName: user ? user.lastName : '',
    email: user ? user.email : '',
    password: '',
  };
  const { register, handleSubmit, errors, setError, reset } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [user]);

  const onSubmit = async (payload) => {
    if (loading) {
      return;
    }
    setLoading(true);

    try {
      await signUp(payload);
    } catch (error) {
      console.log(error);
      setError('form', { message: error.message });
    }

    setLoading(false);
  };

  return (
    <Container>
      <FormTitle className="h2">
        Please create an account to purchase <br />
        <b>The {title}.</b>
      </FormTitle>

      <FormWrapper>
        <FormSubtitle className="h5">
          We will email you your login details for ongoing access.
        </FormSubtitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <TextInput
              name="firstName"
              placeholder="First name"
              ref={register}
              aria-label="First Name"
              fluid
              error={errors.firstName?.message}
            />
          </FormControl>
          <FormControl>
            <TextInput
              name="lastName"
              placeholder="Last name"
              ref={register}
              aria-label="Last Name"
              fluid
              error={errors.lastName?.message}
            />
          </FormControl>
          <FormControl>
            <TextInput
              name="email"
              placeholder="Email address"
              aria-label="Email address"
              ref={register}
              fluid
              error={errors.email?.message}
            />
          </FormControl>

          <FormControl>
            <TextInput
              name="password"
              placeholder="Password"
              aria-label="Password"
              type="password"
              ref={register}
              fluid
              error={errors.password?.message}
            />
          </FormControl>

          <Disclaimer>
            Already have an account?{' '}
            <Link to="/login?intent=/checkout/signup">Click here to login</Link>
            .
          </Disclaimer>

          <Disclaimer>
            By continuing you indicate that you've read and agree to our Terms
            of Service and <Link to="/privacypolicy">Privacy Policy</Link>.
          </Disclaimer>

          <Button fluid type="submit" disabled={loading}>
            Next Step
          </Button>

          {loading && <PositionedLoader />}

          {errors && errors.form && (
            <ErrorText>{errors.form?.message}</ErrorText>
          )}
        </Form>
      </FormWrapper>
    </Container>
  );
};

export default SignupForm;
