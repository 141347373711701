import React, { useEffect, useState } from 'react';
import { navigate, graphql } from 'gatsby';
import styled from 'styled-components';

import RegisteredLayout from '../layout/RegisteredLayout';
import { useSiteMetaContext } from '../context/SiteMetaContext';
import { useUserContext } from '../context/UserContext';
import Seo from '../components/Seo';
import BuyNowCtaSection from '../sections/buyNowCta';
import ThankYouStepsSection from '../sections/thankYouSteps';
import YourHostSection from '../sections/yourHost';
import SponsorsSection from '../sections/sponsors';
import Meditations from '../sections/meditations';

const ThankyouPage = (props) => {
  const { location, data: { allAirtable: { edges: meditations } } } = props;
  const context = useSiteMetaContext();
  const { register: registerForConference } = useUserContext();

  const [hasRegistered, setHasRegistered] = useState(false);


  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleRegister = async (data) => {
    await registerForConference(data);

    setHasRegistered(true);
    console.info('Has Registered.')

    if (window) {
      navigate(window.location.pathname);
    }
  };

  useEffect(() => {
    if (params?.inf_field_Email && !hasRegistered) {
      const affiliateId = localStorage.getItem('superconferenceAffiliate')

      handleRegister({
        infusionsoftId: params.contactId,
        affiliateId,
        email: params.inf_field_Email.toLowerCase(),
        firstName: params.inf_field_FirstName,
      });
    }
  }, [params]);

  return (
    <RegisteredLayout location={location}>
      <Seo title="Free Meditations" />

      <Background className="mt-4">
        <Meditations meditations={meditations} />
      </Background>

      <BackgroundWhite className="py-12">
        <div className="flex flex-col items-center space-y-4">
          <div className="font-medium text-xl text-center">
            <p>Thank you for registering for your 10 free meditations</p>
            <p>Your registration also includes a <strong>FREE PLACE</strong> at the</p>
            <p className="text-2xl font-bold">{context.title}</p>
            <p className="text-lg font-normal mt-4">The conference features in-depth interviews with over 70 of the world's top trauma experts, as well as a number of tools, techniques and strategies to understand and heal your trauma. </p>
          </div>
          <ul className="text-base">
            <li><a title="Get your meditations" target="_blank" href="https://vimeo.com/showcase/9406975">Download</a> your meditations</li>
            <li><a title="Bookmark the agenda page" href="#stepBookmark">Bookmark</a> the conference agenda page</li>
            <li><a title="Access free gifts" href="#stepGifts">Access</a> your free gifts</li>
            <li><a title="Get lifetime access" target="_blank" href="/sales">Purchase</a> lifetime access</li>
          </ul>
          <p>The conference will be available from {context.conferenceDateString} in your local time zone</p>
          <p>You can find out more about each speaker by heading to the <a title="Bookmark the agenda page" href="#stepBookmark">agenda</a> page below.</p>
        </div>
      </BackgroundWhite>

      <ThankYouStepsSection />

      <YourHostSection showHeader={false} removeTopPadding={true} />

      <BuyNowCtaSection />

      <SponsorsSection />
    </RegisteredLayout>
  );
};

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
  a:hover {
    text-decoration: underline;
  }
`;

const BackgroundWhite = styled.div`
  position: relative;
  a {
    color: ${({ theme }) => theme.colours.primary};
    font-weight: bold;
  }
  a:hover {
    text-decoration: underline;
  }
`;

export const query = graphql`
    query {
      allAirtable(
        filter: {
          table: { eq: "Session" }
          data: { Category: { eq: "Meditation" } }
        }
        sort: { fields: data___Day___data___Date, order: ASC }
      ) {
        edges {
          node {
            id
            data {
              Slug
              Name
              Video
              Tagline {
                childMarkdownRemark {
                  html
                }
              }              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 320
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

export default ThankyouPage;
