import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    h1, h2, h3, .h1, .h2, .h3, h4, .h4 {
        margin-block-start: 0;
        margin-block-end: 0;
        line-height: 1.4;
        font-family: ${({ theme }) => theme.font.families.title}, sans-serif;
        margin-top: 0;
        u {
            text-underline-position: under;
        }
        span {
            text-transform: uppercase;
            font-weight: 900;
        }
    }
    .h1 {
        font-weight: 900;
        font-size: ${({ theme }) => theme.font.sizes.xxxl};
        margin: 0 0 ${({ theme }) => theme.sizes.lvl4} 0;
        ${({ theme }) => theme.breakpoint('phoneLarge')`
            font-size: ${theme.font.sizes.xxxxl};
        `}
        ${({ theme }) => theme.breakpoint('tabletPortrait')`
            font-size: ${theme.font.sizes.xxxxxl};
        `}
    }
    .h2 {
        font-size: ${({ theme }) => theme.font.sizes.xxl};
        margin: 0 0 ${({ theme }) => theme.sizes.lvl3} 0;
        ${({ theme }) => theme.breakpoint('phoneLarge')`
            font-size: ${theme.font.sizes.xxxl};
        `}
        ${({ theme }) => theme.breakpoint('tabletPortrait')`
            font-size: ${({ theme }) => theme.font.sizes.xxxxl};
        `}
    }
    .h3 {
        font-size: ${({ theme }) => theme.font.sizes.xl};
        margin:  0 0 ${({ theme }) => theme.sizes.lvl2} 0;
        ${({ theme }) => theme.breakpoint('tabletLandscape')`
          font-size: ${theme.font.sizes.xxl};  
        `}
        ${({ theme }) => theme.breakpoint('medium')`
          font-size: ${theme.font.sizes.xxxl};  
        `}
    }
    .h4 {
        font-size: ${({ theme }) => theme.font.sizes.lg};
        margin: 0 0 ${({ theme }) => theme.sizes.lvl1} 0;
        ${({ theme }) => theme.breakpoint('tabletLandscape')`
          font-size: ${theme.font.sizes.xl};  
        `}
        ${({ theme }) => theme.breakpoint('medium')`
          font-size: ${theme.font.sizes.xxl};  
        `}
    }
    .h2, .h3, .h4 {
        font-weight: 400;
    }


    html, body {
        max-width: 100%;
        line-height: 1.8;
        font-weight: 300;
        font-family: ${({ theme }) => theme.font.families.body}, sans-serif;
        font-size: 16px; //16px = rem
     
    }

    p, .p {
        max-width: 50rem;
        line-height: 1.8;
        ${({ theme }) => theme.breakpoint('desktop')`
            font-size ${({ theme }) => theme.font.sizes.lg};
        `}
    }

    li, .li {
        line-height: 1.5;
        ${({ theme }) => theme.breakpoint('desktop')`
            font-size ${({ theme }) => theme.font.sizes.lg};
        `} 
    }

    strong, .strong {
        font-family: ${({ theme }) => theme.font.families.body};
        font-weight: 700;
    }
    i, .i {
        font-family: ${({ theme }) => theme.font.families.body};
        font-style: italic;
    }
    nav, .nav {
        font-family: ${({ theme }) => theme.font.families.body};
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: ${({ theme }) => theme.font.sizes.sm};
        a {
            text-decoration: none;

        }
    }
    ul {
        margin: 0;
        padding-left: ${({ theme }) => theme.sizes.lvl1};
        li {
            padding-left: ${({ theme }) => theme.sizes.lvl1};
            margin-bottom: ${({ theme }) => theme.sizes.lvl1};
            list-style-type: '•';
            ::marker {
                color: ${({ theme }) => theme.colours.bulletPointColor};
            }
        }
    }
`;

export default GlobalStyle;
