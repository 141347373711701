import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';

import config from '../../firebaseConfig';

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */
    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.apiUrl = config.apiUrl;

    /* Firebase APIs */
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    this.storage = app.storage();
    this.analytics = app.analytics();

    /* Storage Refs */
    // Points to the root reference
    this.storageRef = this.storage.ref();
    this.storageTranscriptsRef = this.storageRef.child('transcripts');
    this.storageAudioRef = this.storageRef.child('audio');
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = ({
    email,
    password,
    firstName,
    lastName,
  }) =>
    this.auth.createUserWithEmailAndPassword(email, password).then((user) => {
      const payload = {
        firstName,
        lastName,
        email,
      };

      this.registration(user.user.email)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            const registrationData = snapshot.data();
            payload.affiliateId = registrationData.affiliateId || null;
            payload.isFb = registrationData.isFb || null;
            payload.infusionsoft_id = registrationData.infusionsoftId || null;
          }
          this.customer(user.user.uid)
            .set(payload)
            .then(() =>
              fetch(`${this.apiUrl}/setUserIp`, {
                method: 'POST',
                mode: 'no-cors', // 'cors' by default
                body: user.user.uid,
              }).catch((err) => console.error('Error recording user IP', err))
            );
        });
    });

  doSignInWithEmailAndPassword = ({ email, password }) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email, config) =>
    this.auth.sendPasswordResetEmail(email, config);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: window.location.href,
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        next(authUser);
      } else {
        fallback();
      }
    });

  // *** User API ***

  customers = () => this.db.collection('customers');
  customer = (uid) => this.db.doc(`customers/${uid}`);
  registrations = () => this.db.collection('registrations');
  registration = (email) => this.db.doc(`registrations/${email}`);
  createPaidCustomer = () => this.functions.httpsCallable('createPaidCustomer');
  deletePaidCustomer = () => this.functions.httpsCallable('deletePaidCustomer');
  addPaymentToCustomer = () =>
    this.functions.httpsCallable('addPaymentToCustomer');

  // *** Payment API ***
  customerStripePayments = (uid) =>
    this.customer(uid).collection('stripe_payments');
  customerPaypalPayments = (uid) =>
    this.customer(uid).collection('paypal_payments');
  paypalCreateOrder = () => this.functions.httpsCallable('createPaypalPayment');
  paypalConfirmOrder = () =>
    this.functions.httpsCallable('confirmPaypalPayment');
  exportCustomers = () => this.functions.httpsCallable('exportCustomers');

  // *** Storage API ***
  getTranscriptDownloadLink = (fileName) =>
    this.storageTranscriptsRef.child(fileName).getDownloadURL();
  getAudioDownloadLink = (fileName) =>
    this.storageAudioRef.child(fileName).getDownloadURL();
}

let firebase;

function getFirebase(app, auth, database) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database);
  }

  return firebase;
}

export { getFirebase };
export default getFirebase;
