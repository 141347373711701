import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const TipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const Tip = styled.div`
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  transition: opacity 0.5s;
  position: ${({ $absolute }) => ($absolute ? 'absolute' : 'relative')};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  padding: ${({ theme }) => theme.sizes.lvl2};
  color: ${({ theme }) => theme.colours.text};
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
  //   z-index: ${({ theme }) => theme.zindexes.tooltip};
  white-space: nowrap;
  width: max-content;
  margin-bottom: ${({ $absolute, theme }) =>
    $absolute ? '0' : theme.sizes.lvl4};

  ${({ $direction, $absolute, theme }) => {
    switch ($direction) {
      default:
      case 'TOP':
        return `top: -${theme.sizes.lvl2}; 
        left: 50%;
        transform: ${$absolute ? 'translateX(-50%) translateY(-100%)' : 'translateX(-50%)'
          };`;
      case 'BOTTOM':
        return `bottom:  -${theme.sizes.lvl2};
        left: 50%;
        transform: ${$absolute ? 'translateX(-50%) translateY(100%)' : 'translateX(-50%)'
          };`;
      case 'LEFT':
        return `left: -${theme.sizes.lvl2};
        top: 50%;
        transform: ${$absolute ? 'translateX(-100%) translateY(-50%)' : 'translateY(-50%)'
          };`;

      case 'RIGHT':
        return `right: -${theme.sizes.lvl2};
        top: 50%;
        transform: ${$absolute ? 'translateX(100%) translateY(-50%)' : 'translateY(-50%)'
          };`;
    }
  }}

  &::before {
    content: '';
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: ${({ theme }) => theme.sizes.lvl1};
    margin-left: -${({ theme }) => theme.sizes.lvl1};
    ${({ $direction, theme }) => {
    switch ($direction) {
      default:
      case 'TOP':
        return `top: 100%; 
          left: 50%;
          border-top-color: ${theme.colours.backgroundPaper}`;
      case 'BOTTOM':
        return `bottom: 100%; 
          left: 50%;
          border-bottom-color: ${theme.colours.backgroundPaper}`;
      case 'LEFT':
        return `right: -${theme.sizes.lvl2};
            top: 50%;
            transform: translateX(0%) translateY(-50%);
            border-left-color: ${theme.colours.backgroundPaper}`;
      case 'RIGHT':
        return `left: -${theme.sizes.lvl1};
            top: 50%;
            transform: translateX(0) translateY(-50%);
            border-right-color: ${theme.colours.backgroundPaper}`;
    }
  }}
  }
`;

const Tooltip = ({
  className,
  delay = 0,
  direction = 'TOP',
  alwaysOpen = false,
  absolute,
  children,
  content,
}) => {
  let timeout = 0;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(!!alwaysOpen);
  };

  useEffect(() => {
    if (alwaysOpen) showTip();
  }, [alwaysOpen]);

  return (
    <TipWrapper
      className={className}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      <Tip $active={active} $direction={direction} $absolute={absolute}>
        {content}
      </Tip>
    </TipWrapper>
  );
};

export { Tooltip };
export default Tooltip;
