import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function MeditationCard (props) {
    return (
        <article className={`relative ${props.colSpan}`}>
            <div>
                <GatsbyImage
                    className="rounded-t-2xl"
                    alt={`Meditation ${props.title} image`}
                    image={getImage(props.image)}
                />
            </div>
            <div className="bg-[#359FCB] p-8 rounded-b-2xl text-white">
                <p className="first-line:text-4xl first-line:font-bold text-2xl pt-1">{props.title}</p>
                <p
                    className="text-base pt-2.5 opacity-75"
                    dangerouslySetInnerHTML={{
                        __html: props.body
                    }}
                ></p>
                {props.video && <div
                    className="flex justify-center mt-2">
                    <a
                        className="text-white"
                        href={props.video}
                        target="_blank"
                        title="Download this meditation">
                        Download</a></div>}
            </div>
            <div
                className="text-white text-2xl font-bold bg-[#359FCB] rounded-full absolute left-4 top-4 h-16 w-16 flex items-center justify-center">
                {props.index + 1}
            </div>
        </article>
    )
}

export default ({ meditations }) => {

    return (
        <div className="mx-auto flex flex-col items-center max-w-[320px] md:max-w-[640px] lg:max-w-[960px]">
            <h1 className="my-16 text-5xl lg:text-7xl text-center font-bold">Your 10 Free Guided Meditations</h1>
            <h2 className="text-2xl text-center font-bold">They are FREE and available to DOWNLOAD NOW</h2>
            <div className="my-8 gap-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-between">
                {meditations.map((meditation, index) => {
                    const data = meditation.node.data;
                    const image = data.Image?.localFiles[0].childImageSharp
                    const body = data.Tagline?.childMarkdownRemark?.html
                    const slug = data.Slug
                    const video = data.Video
                    const colSpan = (index + 1) === meditations.length ? 'col-span-1 lg:col-start-2 lg:col-end-3' : 'col-span-1'
                    return <MeditationCard key={index} image={image} index={index} title={data.Name} body={body} colSpan={colSpan} video={video} slug={slug} />
                })}
            </div>
        </div>

    )
}
