import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Container from '../layout/Container';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

import Carousel from '../components/Carousel';
import { Speaker } from '../types';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const Title = styled.h1`
  font-weight: 700;
  text-align: center;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  `};
`;
const Subtitle = styled.h2`
  text-align: center;
  font-weight: 400;
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
`;

const SpeakerWrapper = styled(Link)`
  color: ${({ theme }) => theme.colours.text};
  text-decoration: none;
  text-align: center;
  &:hover {
    text-decoration: underline;
  }
`;
const SpeakerAvatarContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  overflow: hidden;
  height: ${({ theme }) => theme.sizes.sizeByFactor(40)};
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`;
const SpeakerName = styled.h1`
  font-weight: 700;
`;
const SessionName = styled.div``;

interface SpeakerElementProps {
  name: string;
  title: string;
  slug: string;
  image: ImageDataLike;
}
const SpeakerElement = ({ name, title, image, slug }: SpeakerElementProps) => {
  return (
    <SpeakerWrapper to={`/session/${slug}`}>
      <SpeakerAvatarContainer className="max-w-screen rounded-lg">
        <GatsbyImage
          alt={`Speaker ${name} image`}
          image={getImage(image)}
          objectPosition="top"
          className="rounded-lg"
          imgClassName="rounded-lg"
        />
      </SpeakerAvatarContainer>
      <SpeakerName className="h4">{name}</SpeakerName>
      <SessionName>{title}</SessionName>
    </SpeakerWrapper>
  );
};

const SpeakersCarousel = ({ speakers }: { speakers: Speaker[] }) => {
  const speakerElements = speakers?.map((speaker, index) => (
    <SpeakerElement
      key={index}
      name={speaker.name}
      title={speaker.tagline}
      slug={speaker.slug}
      image={speaker.image}
    />
  ));

  return (
    <Background>
      <Container className="">
        <Carousel
          className="hidden lg:block"
          items={speakerElements}
          numberOfCards={4}
          gutter={32}
          showChevrons
        />
        <Carousel
          className="hidden md:block lg:hidden"
          items={speakerElements}
          numberOfCards={3}
          gutter={16}
          showChevrons
        />
        <Carousel
          className="hidden sm:block md:hidden"
          items={speakerElements}
          numberOfCards={2}
          gutter={16}
          showChevrons
        />
        <Carousel
          className="sm:hidden"
          items={speakerElements}
          numberOfCards={1}
          showChevrons
        />
      </Container>
    </Background>
  );
};

export { SpeakersCarousel };
export default SpeakersCarousel;
