import React from 'react';

import { useSiteMetaContext } from '../context/SiteMetaContext';
import Layout from '../layout/Layout';
import PaymentSection from '../sections/payment';
import Header from '../components/SalesHeader';
import GuaranteeBigSection from '../sections/guaranteeBig';
import SponsorsSection from '../sections/sponsors';

const CheckoutPage = ({ location }) => {
  const {
    conferenceDateString,
    logoColouredFluidObj,
    title,
  } = useSiteMetaContext();

  return (
    <Layout>
      <Header logoFluidObj={logoColouredFluidObj} siteTitle={title}>
        <span>FREE &amp; ONLINE</span>
        <br />
        {conferenceDateString}
      </Header>
      <PaymentSection location={location} basePath="/checkout" />
      <GuaranteeBigSection isWhite={false} />
      <SponsorsSection />
    </Layout>
  );
};

export default CheckoutPage;
