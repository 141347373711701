import React, { useState, ReactNode } from 'react';
import styled from 'styled-components';
import ItemsCarousel from 'react-items-carousel';

const CarouselDotContainer = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.sizes.lvl2};
`;
const Dot = styled.span<{ $active?: boolean }>`
  font-size: ${({ theme }) => theme.font.sizes.xs};
  margin: 0 ${({ theme }) => theme.sizes.lvl1};
  color: ${({ $active, theme }) =>
    $active ? theme.colours.secondary : theme.colours.textDisabled};
`;

const MaterialIcon = styled.span`
  font-size: ${({ theme }) => theme.font.sizes.xxxl};
  color: ${({ theme }) => theme.colours.textDisabled};
`;

interface CarouselProps {
  className?: string;
  items: ReactNode[];
  numberOfCards?: number;
  showChevrons?: boolean;
  showDots?: boolean;
  showSlither?: boolean;
  gutter?: number;
}

const Carousel = ({
  className,
  items,
  showSlither,
  numberOfCards = 1,
  showChevrons,
  showDots,
  gutter = 8,
}: CarouselProps) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  return (
    <>
      <ItemsCarousel
        classes={{ wrapper: className }}
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={numberOfCards}
        rightChevron={
          showChevrons && (
            <MaterialIcon className="material-icons">
              chevron_right
            </MaterialIcon>
          )
        }
        leftChevron={
          showChevrons && (
            <MaterialIcon className="material-icons">chevron_left</MaterialIcon>
          )
        }
        outsideChevron
        chevronWidth={32}
        gutter={gutter}
      >
        {items}
      </ItemsCarousel>
      {showDots && (
        <CarouselDotContainer>
          {[...Array(items.length)].map((e, i) => (
            <Dot
              className="material-icons"
              key={`dot_${i}`}
              $active={activeItemIndex === i}
            >
              circle
            </Dot>
          ))}
        </CarouselDotContainer>
      )}
    </>
  );
};

export { Carousel };
export default Carousel;
