import React, { useState, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import Button from './Button';
import IncludedTickPath from '../images/included_tick.svg';
import ExcludedCrossPath from '../images/excluded_cross.svg';

const ListContainer = styled.ul`
  position: relative;
  padding: 0;
  text-align: left;
`;

const Li = styled.li`
  background: url(${({ $included }) =>
      $included ? IncludedTickPath : ExcludedCrossPath})
    no-repeat left center;
  padding: ${({ theme }) =>
    `${theme.sizes.lvl3} ${theme.sizes.lvl1} ${theme.sizes.lvl3} ${theme.sizes.lvl6}`};
  list-style: none;
  margin: 0;
  vertical-align: middle;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  font-weight: 400;
  ${({ $included }) =>
    !$included
      ? 'text-decoration: line-through;color: rgba(0, 0, 0, 0.15);'
      : ''};
  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  b {
    font-weight: 700;
  }
`;

const LiMaterialIcon = styled(Li)`
  background: none;
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '${({ $materialIcon }) => $materialIcon}';
    font-size: 2rem;
    font-family: 'Material Icons';
    color: ${({ theme }) => theme.colours.signal.success};
  }
`;
const Fade = styled.div`
  position: absolute;
  height: ${({ theme }) => theme.sizes.lvl10};
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(transparent 10%, white 75%);
`;

const ShowMoreButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
`;

const IconList = ({ className, listItems, showMoreAfterXItems }) => {
  const [showXItems, setShowXItems] = useState(
    showMoreAfterXItems || listItems.length
  );
  const theme = useContext(ThemeContext);

  const filteredListItems = listItems.slice(0, showXItems);

  return (
    <>
      <ListContainer className={className}>
        {filteredListItems.map((listItem) =>
          listItem.materialIcon ? (
            <LiMaterialIcon
              $included={listItem.included}
              $materialIcon={listItem.materialIcon}
            >
              {listItem.content}
            </LiMaterialIcon>
          ) : (
            <Li $included={listItem.included}>{listItem.content}</Li>
          )
        )}
        {showXItems < listItems.length && <Fade />}
      </ListContainer>
      {showXItems < listItems.length && (
        <ShowMoreButton
          onClick={() => setShowXItems(listItems.length)}
          fluid
          level="secondary"
          icon="expand_more"
          outlineColour={theme.colours.secondaryLight}
          backgroundColour={theme.colours.secondaryLight}
          textColour={theme.colours.secondary}
        >
          Show more
        </ShowMoreButton>
      )}
    </>
  );
};

export default IconList;
