import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import RegisteredLayout from '../layout/RegisteredLayout';
import { useSiteMetaContext } from '../context/SiteMetaContext';
import { useUserContext } from '../context/UserContext';
import Seo from '../components/Seo';
import ThankYouIntroSection from '../sections/thankYouIntro';
import BuyNowCtaSection from '../sections/buyNowCta';
import ThankYouStepsSection from '../sections/thankYouSteps';
import YourHostSection from '../sections/yourHost';
import SponsorsSection from '../sections/sponsors';

const ThankyouPage = ({ location }) => {
  const context = useSiteMetaContext();
  const { register: registerForConference } = useUserContext();

  const [hasRegistered, setHasRegistered] = useState(false);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleRegister = async (data) => {
    await registerForConference(data);

    setHasRegistered(true);
    console.info('Has Registered.')

    if (window) {
      navigate(window.location.pathname);
    }
  };

  useEffect(() => {
    if (params?.inf_field_Email && !hasRegistered) {
      const affiliateId = localStorage.getItem('superconferenceAffiliate')

      handleRegister({
        infusionsoftId: params.contactId,
        affiliateId,
        email: params.inf_field_Email.toLowerCase(),
        firstName: params.inf_field_FirstName,
      });
    }
  }, [params]);

  return (
    <RegisteredLayout location={location}>
      <Seo title="Thankyou" />

      <ThankYouIntroSection context={context} />

      <ThankYouStepsSection />

      <YourHostSection showHeader={false} removeTopPadding={true} />

      <BuyNowCtaSection />

      <SponsorsSection />
    </RegisteredLayout>
  );
};

export default ThankyouPage;
