import React from 'react';
import { Router } from '@reach/router';
import { graphql, useStaticQuery, navigate } from 'gatsby';

import { plans } from '../constants/plans';
import PaymentStripe from '../components/PaymentStripe';
import SignupForm from '../components/SignupForm';
import ShippingAddressForm from '../components/ShippingAddressForm';
import { useUserContext } from '../context/UserContext';
import PaymentSummary from '../components/PaymentSummary';
import PaymentSuccess from '../components/PaymentSuccess';

const Summary = ({ basePath, searchParams }) => {
  const { user, selectedPlan } = useUserContext();

  if (selectedPlan?.requiresAddress && (!user || !user.address)) {
    navigate(`${basePath}/address?${searchParams.toString()}`, {
      replace: true,
    });
    return null;
  }

  return (
    <PaymentSummary
      selectedPlan={selectedPlan}
      address={user?.address}
      selectCard={() => navigate(`${basePath}/card?${searchParams.toString()}`)}
      searchParams={searchParams}
      onPayPalSuccess={() =>
        navigate(`${basePath}/success?${searchParams.toString()}`, {
          replace: true,
        })
      }
    />
  );
};

const Card = () => {
  const data = useStaticQuery(
    graphql`
      query {
        visa: file(relativePath: { eq: "visa.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        master: file(relativePath: { eq: "mastercard.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        discover: file(relativePath: { eq: "discover.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        amex: file(relativePath: { eq: "amex.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <PaymentStripe
      masterdardImage={data?.master.childImageSharp.fluid}
      amexImage={data?.amex.childImageSharp.fluid}
      discoverImage={data?.discover.childImageSharp.fluid}
      visaImage={data?.visa.childImageSharp.fluid}
    />
  );
};

const Signup = ({ basePath, searchParams }) => {
  const { isLoggedIn } = useUserContext();

  if (isLoggedIn) {
    navigate(`${basePath}/summary?${searchParams.toString()}`, {
      replace: true,
    });
    return null;
  }
  return <SignupForm />;
};

const ShippingAddress = ({ basePath, searchParams }) => {
  return (
    <ShippingAddressForm
      onSuccess={() =>
        navigate(`${basePath}/summary?${searchParams.toString()}`)
      }
    />
  );
};

const PaymentSection = ({ basePath, location }) => {
  const {
    isPaid,
    hasPaidForProduct,
    isLoggedIn,
    setPlan,
    selectedPlan,
  } = useUserContext();

  const searchParams = new URLSearchParams(location.search);
  const searchParamPlan = searchParams.get('selectedPlan');

  if (typeof window === 'undefined') {
    return null;
  }

  if (!searchParamPlan) {
    navigate(`/sales`, { replace: true });
    return null;
  }
  const plan = plans.find((p) => p.id === searchParamPlan);
  if (!plan) {
    navigate(`/sales`, { replace: true });
    return null;
  } else if (!selectedPlan || selectedPlan.id !== plan.id) {
    setPlan(plan);
    return null;
  }

  if (!isLoggedIn && !location.pathname.includes('signup')) {
    navigate(`${basePath}/signup?${searchParams.toString()}`, {
      replace: true,
    });
    return null;
  }

  if (
    isPaid &&
    hasPaidForProduct(plan.name) &&
    !location.pathname.includes('success')
  ) {
    navigate(`${basePath}/success?${searchParams.toString()}`);
  }

  return (
    <Router basepath={basePath}>
      <Summary
        path="/summary"
        basePath={basePath}
        searchParams={searchParams}
      />
      <PaymentSuccess path="/success" basePath={basePath} />
      <Signup path="/signup" basePath={basePath} searchParams={searchParams} />
      <ShippingAddress
        path="/address"
        basePath={basePath}
        searchParams={searchParams}
      />
      <Card path="/card" />
    </Router>
  );
};

export default PaymentSection;
