import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const HostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    flex-direction: row;
    align-items:flex-start;
  `}
`;
const HostImage = styled.div`
  height: 400px;
  width: 100%;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    height: auto;
    width: 300px;
    padding: 0 ${({ theme }) => theme.sizes.lvl4} 0 0;
  `}
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    flex-basis: 50%;
  `}
  .gatsby-image-wrapper {
    height: 100%;
    img {
      object-position: top !important;
    }
  }
`;
export const HostContent = styled.div`
  flex-basis: 50%;
  text-align: left;
  padding: ${({ theme }) => theme.sizes.lvl3} 0;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    padding:0px;
  `}
`;
export const Name = styled.h4`
  font-weight: 900;
  font-family: ${({ theme }) => theme.font.families.body};
  margin: 0;
  font-size: ${({ theme }) => theme.font.sizes.lg};
`;

const Host = ({ name, html, fluidAvatar, className, website }) => {
  return (
    <HostContainer className={className}>
      <HostImage>
        <Img fluid={fluidAvatar} />
      </HostImage>
      <HostContent>
        <Name>{name}</Name>
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
        {website && (
          <p className="p">
            <a href={website} target="_blank" rel="noreferrer">
              Visit website
            </a>
          </p>
        )}
      </HostContent>
    </HostContainer>
  );
};

Host.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  fluidAvatar: PropTypes.any,
  html: PropTypes.string,
  withWebsite: PropTypes.bool,
  website: PropTypes.string,
};

Host.defaultProps = {
  className: '',
  fluidAvatar: null,
  html: '',
  children: null,
  website: null,
};

export default Host;
