import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Container from '../layout/Container';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.white};
`;

const SponsorsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    && {
      padding-top: ${({ theme }) => theme.sizes.lvl3};
      padding-bottom: ${({ theme }) => theme.sizes.lvl2};
    }
    flex-direction: row;
  `}
`;

const LogoContainer = styled.div`
  width: 80%;
  margin: 0 auto ${({ theme }) => theme.sizes.lvl3} auto;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    width: 100%;
    max-width: 256px;

    padding: 0px ${({ theme }) => theme.sizes.lvl1};
    margin: 0px;
  `}
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
  white-space: nowrap;
  padding: 0px ${({ theme }) => theme.sizes.lvl3};
`}
`;

const Title = styled.h4`
  && {
    text-align: center;
    padding: 0px;
    margin: 0px;
    padding-bottom: ${({ theme }) => theme.sizes.lvl6};

    ${({ theme }) => theme.breakpoint('tabletPortrait')`
      padding-bottom: 0;
  `}
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      white-space: nowrap;
  `}
  }
`;

const SponsorsSection = () => {
  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "Sponsors" }, data: { Name: { ne: null } } }
      ) {
        edges {
          node {
            data {
              Name
              Logo {
                localFiles {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `);

  const sponsors = allAirtable?.edges?.map((edge) => edge?.node) || [];

  return (
    <Background>
      <SponsorsContainer>
        <Title className="h4">
          With thanks to our <span>Sponsors</span>:
        </Title>
        {sponsors.map((sponsor) => (
          <LogoContainer key={`logo_${sponsor.id}`}>
            <Img
              fluid={sponsor.data?.Logo?.localFiles[0]?.childImageSharp?.fluid}
            />
          </LogoContainer>
        ))}
      </SponsorsContainer>
    </Background>
  );
};

export default SponsorsSection;
