import React, { useRef, useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import Container from '../../layout/Container';
import RegisterForm from '../../components/RegisterForm';
import ScrollTarget from '../../components/ScrollTarget';
import Button from '../../components/Button';
import { useSiteMetaContext } from '../../context/SiteMetaContext';

const Inner = styled(Container)`
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ $stickyRegistration, theme }) =>
    $stickyRegistration ? `padding: ${theme.sizes.lvl1};` : ''};
`;

const Background = styled.div`
  position: relative;
  display: flex;

  background-color: ${({ theme }) => theme.colours.backgroundAlt};
  color: #faf7f2;
  transition: top 0.5s;

  z-index: ${({ theme }) => theme.zIndexes.stickyRegistrationForm};

  ${({ $stickyRegistration }) =>
    $stickyRegistration ? 'position: fixed;top: 0;right: 0;left: 0;' : ''};
`;
const RegistrationCardInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ $stickyRegistration }) =>
    $stickyRegistration ? 'flex-start' : 'center'};
  text-align: ${({ $stickyRegistration }) =>
    $stickyRegistration ? 'left' : 'center'};
  justify-content: center;
`;

const Title = styled.h2`
  && {
    text-transform: uppercase;
    margin-bottom: ${({ theme }) => theme.sizes.lvl4};
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
    br {
      display: none;
    }
  `}
  }
`;
const StickyTitle = styled.h2`
  && {
    margin: 0 0 ${({ theme }) => theme.sizes.lvl1};
    font-size: ${({ theme }) => theme.font.sizes.xl};
  }
`;

const Subtitle = styled.h3`
  && {
    font-size: ${({ theme }) => theme.font.sizes.lg};

    ${({ theme }) => theme.breakpoint('desktop')`
      font-size: ${theme.font.sizes.xl};  
    `}
    ${({ theme }) => theme.breakpoint('medium')`
      font-size: ${theme.font.sizes.xxl};  
    `}
  }
`;

const ButtonLink = styled.a`
  text-decoration: none;
`;

const LandingStickyRegistrationSection = () => {
  const { conferenceDateString, title } = useSiteMetaContext();

  const [stickyRegistration, setStickyRegistration] = useState(false);
  const registrationRef = useRef();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = -currPos.y > registrationRef.current.offsetTop;
      if (isShow !== stickyRegistration) setStickyRegistration(isShow);
    },
    [stickyRegistration]
  );

  const mobileScrolled = isMobile && stickyRegistration;
  const mobileUnscrolled = isMobile && !stickyRegistration;
  const desktopScrolled = !isMobile && stickyRegistration;
  const desktopUnscrolled = !isMobile && !stickyRegistration;

  return (
    <>
      <ScrollTarget ref={registrationRef} />
      <Background $stickyRegistration={stickyRegistration}>
        <Inner $stickyRegistration={stickyRegistration}>
          {(mobileUnscrolled || desktopScrolled || desktopUnscrolled) && (
            <RegistrationCardInner $stickyRegistration={stickyRegistration}>
              {stickyRegistration ? (
                <StickyTitle className="h4">
                  Join {title} {conferenceDateString}, It's FREE &amp; ONLINE
                </StickyTitle>
              ) : (
                <>
                  <Subtitle className="h3">
                    Join {title} {conferenceDateString}
                  </Subtitle>
                  <Title className="h2 strong">It's free &amp; online</Title>
                </>
              )}

              <RegisterForm
                inline
                slim={stickyRegistration}
                leftAligned={stickyRegistration}
                hideTooltip={stickyRegistration}
              />
            </RegistrationCardInner>
          )}
          <div />

          {mobileScrolled && (
            <ButtonLink href="#signup">
              <Button fluid>Click here to register</Button>
            </ButtonLink>
          )}
        </Inner>
      </Background>
    </>
  );
};

export default LandingStickyRegistrationSection;
