import React, { useEffect, useState } from 'react';
import { navigate, Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { useUserContext } from '../context/UserContext';

import Button from './Button';
import Loader from './Loader';
import TextInput from './TextInput';

const Wrapper = styled.div`
  width: 100%;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Inner = styled.div`
  width: 100%;
  max-width: ${({ $inline }) => ($inline ? '100%' : '640px')};
  display: block;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: ${({ $inline }) => ($inline ? 'flex' : 'block')};
    justify-content: center;
  `}
`;

const ErrorText = styled.p`
  font-size: ${({ theme }) => theme.sizes.lvl2};
  color: ${({ theme }) => theme.colours.signal.error};
  margin: 0 ${({ theme }) => theme.sizes.lvl1};
`;

const FormControl = styled.div`
  margin: 0 ${({ theme }) => `${theme.sizes.lvl1} ${theme.sizes.lvl2}`};
`;

const Disclaimer = styled.p`
  text-align: center;
  line-height: 150%;
  font-size: ${({ theme }) => theme.font.sizes.xxs};
  margin: 0 auto;
  padding-bottom: ${({ theme }) => theme.sizes.lvl3};
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    font-size: ${({ theme }) => theme.font.sizes.xs};
  `}
`;
const PositionedLoader = styled(Loader)`
  margin: 0 auto;
`;

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Must be a valid email')
    .trim()
    .label('Your email'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Must be at least 6 characters long')
    .label('Your password'),
});

const SigninForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const intent = searchParams.get('intent');
  const { signIn, isLoggedIn, user } = useUserContext();
  const { register, handleSubmit, errors, setError, reset } = useForm({
    defaultValues: {
      email: user ? user.email : '',
      password: '',
    },
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user)
      reset({
        email: user ? user.email : '',
        password: '',
      });
  }, [user]);

  if (isLoggedIn) {
    navigate(intent || '/agenda');
  }

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await signIn(data);
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        setError('form', { message: `Can't find a user with this email` });
      } else {
        setError('form', { message: error.message });
      }
    }
    setLoading(false);
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Inner>
          <FormControl>
            <TextInput
              name="email"
              placeholder="Email address"
              aria-label="Email address"
              ref={register}
              fluid
              error={errors.email?.message}
            />
          </FormControl>
          <FormControl>
            <TextInput
              name="password"
              placeholder="Password"
              aria-label="Password"
              type="password"
              ref={register}
              fluid
              error={errors.password?.message}
            />
          </FormControl>

          <Disclaimer>
            Forgotten your password?{' '}
            <Link to="/resetpassword?intent=/checkout/signup">
              Click here to reset
            </Link>
            .
          </Disclaimer>

          <FormControl>
            <Button type="submit" fluid disabled={loading}>
              Log in
            </Button>
          </FormControl>

          {loading && <PositionedLoader />}

          {errors && errors.form && (
            <ErrorText>{errors.form?.message}</ErrorText>
          )}
        </Inner>
      </Form>
    </Wrapper>
  );
};

export default SigninForm;
