import React from 'react';
import styled from 'styled-components';

import GuaranteeIconPath from '../images/guaranteeIcon.svg';
import GuaranteeSvg from '../images/guarantee.inline.svg';
import Container from '../layout/Container';

const Background = styled.section`
  background-color: ${({ theme }) => theme.colours.backgroundGuarantee};
`;

const Wrapper = styled(Container)`
  color: ${({ theme }) => theme.colours.black};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme.breakpoint('desktop')`
    flex-direction: row;
  `}
`;

const ContentWrapper = styled.div`
  ${({ theme }) => theme.breakpoint('desktop')`
   padding-left: ${theme.sizes.lvl8};
  `};
`;

const ImageSection = styled.div`
  align-items: center;
  justify-content: center;
  padding: 0px;
`;

const PreTitle = styled.strong`
  && {
    display: block;
    color: ${({ theme }) => theme.colours.textGuarantee};
    text-transform: uppercase;
    margin: 0 auto;
    text-align: center;
    ${({ theme }) => theme.breakpoint('desktop')`
      text-align: left;
      margin: 0;
    `};
  }
`;
const Title = styled.h1`
  && {
    display: block;
    margin: 0 auto ${({ theme }) => theme.sizes.lvl1};
    text-align: center;
    ${({ theme }) => theme.breakpoint('desktop')`
      text-align: left;
      margin: 0 0  ${({ theme }) => theme.sizes.lvl1} 0;
    `};
  }
`;

const ListContainer = styled.ul`
  text-align: left;
  li {
    font-size: ${({ theme }) => theme.sizes.lvl2};
    background: url(${GuaranteeIconPath}) no-repeat left center;
    padding: ${({ theme }) =>
      `${theme.sizes.lvl1} ${theme.sizes.lvl1} ${theme.sizes.lvl1} ${theme.sizes.lvl5}`};
    list-style: none;
    margin: 0;
    vertical-align: middle;
    &:last-child {
      margin-bottom: 0;
    }
    b {
      font-weight: 700;
    }
  }
`;

const Guarantee2Section = ({ className }) => {
  return (
    <Background className={className}>
      <Wrapper>
        <ImageSection>
          <GuaranteeSvg />
        </ImageSection>
        <ContentWrapper>
          <PreTitle className="strong">You're protected</PreTitle>
          <Title className="h3 strong">100% Money Back Guarantee</Title>
          <ListContainer>
            <li>100% Protection</li>
            <li>30-day money back guarantee</li>
            <li>No questions asked</li>
          </ListContainer>
        </ContentWrapper>
      </Wrapper>
    </Background>
  );
};

export default Guarantee2Section;
