import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from '../../layout/Container';
import RegisterForm from '../../components/RegisterForm';
import { useSiteMetaContext } from '../../context/SiteMetaContext';

const Inner = styled(Container)`
  position: relative;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Background = styled.div`
  position: relative;
  display: flex;

  background-color: ${({ theme }) => theme.colours.backgroundAlt};
  color: #faf7f2;
  transition: top 0.5s;

  z-index: ${({ theme }) => theme.zIndexes.stickyRegistrationForm};

  ${({ $stickyRegistration }) =>
    $stickyRegistration ? 'position: fixed; bottom: 0;' : ''};
`;
const RegistrationCardInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ $stickyRegistration }) =>
    $stickyRegistration ? 'flex-start' : 'center'};
  text-align: ${({ $stickyRegistration }) =>
    $stickyRegistration ? 'left' : 'center'};
  justify-content: center;
`;

const Title = styled.h2`
  && {
    text-transform: uppercase;
    margin-bottom: ${({ theme }) => theme.sizes.lvl2};
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
    margin-bottom: ${({ theme }) => theme.sizes.lvl4};
    br {
      display: none;
    }
  `}
  }
`;

const Subtitle = styled.h3`
  && {
    margin-bottom: ${({ theme }) => theme.sizes.lvl1};
    font-size: ${({ theme }) => theme.font.sizes.lg};
    span {
      display: none;
    }

    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      margin-bottom: ${({ theme }) => theme.sizes.lvl2};
      font-size: ${theme.font.sizes.xl};  
      span {
        display: inline;
      }
      br {
        display: none;
      }
    `}
    ${({ theme }) => theme.breakpoint('medium')`
      font-size: ${theme.font.sizes.xxl};  
    `}
  }
`;

const LandingRegistration = (props) => {
  const { conferenceDateString, title } = useSiteMetaContext();

  return (
    <>
      <Background className={props.className} id="signupform">
        <Inner>
          <RegistrationCardInner>
            <Subtitle className="h3">
              {props.subtitle || `Join ${title}
              ${conferenceDateString}`}
            </Subtitle>
            <Title className="h2 strong">{props.title}</Title>

            <RegisterForm
              inline
              submitLabel={props.formSubmitLabel}
              tooltipLabel={props.formTooltipLabel}
            />
          </RegistrationCardInner>
        </Inner>
      </Background>
    </>
  );
};

LandingRegistration.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  formSubmitLabel: PropTypes.string,
  formTooltipLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

LandingRegistration.defaultProps = {
  title: `It's free & online`
}

export default LandingRegistration;
