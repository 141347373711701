import React, { useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import theme from '../../utils/theme';
import IncludedBlueTickPath from '../../images/included_blue_tick.svg';
import Button from '../Button';
import Icon from '../Icon';
import { caret } from '../../images/icons';
import { useUserContext } from '../../context/UserContext';

const Container = styled.div`
  background-color: white;
  color: ${({ theme }) => theme.colours.text};
  border-radius: ${({ theme }) => theme.sizes.lvl2};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizes.lvl2};

  padding: 0 ${({ theme }) => theme.sizes.lvl3}
    ${({ theme }) => theme.sizes.lvl3};
`;

const Name = styled.h2`
  && {
    margin-bottom: ${({ theme }) => theme.sizes.lvl1};
    font-size: ${({ theme }) => theme.font.sizes.sm};
    font-weight: 400;
    text-transform: uppercase;
  }
`;

const TitlesContainer = styled.div`
  height: ${({ theme }) => theme.sizes.lvl10};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    height: ${({ theme }) => theme.sizes.lvl14};
  `}
`;

const Title = styled.h1`
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.sizes.lvl0};
  line-height: 1.2;
`;

const SubTitle = styled.span`
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.sizes.sm};
  color: rgba(0, 0, 0, 0.4);
`;

const AvatarWidth = '30%';
const StyledAvatarContainer = styled.div`
  width: ${AvatarWidth};
  min-width: ${({ theme }) => theme.sizes.lvl15};
  margin: -${({ theme }) => theme.sizes.lvl1} auto ${({ theme }) => theme.sizes.lvl2};
  border-radius: 9999px;
  box-shadow: 0 0 0 3px white, 0 0 0 6px ${({ theme }) => theme.colours.primary};
  overflow: hidden;
  img {
    border-radius: 9999px;
    object-position: top !important;
  }
  .gatsby-image-wrapper {
    height: 0;
    padding-bottom: 100%;
    border-radius: 9999px;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`;

const PositionedButton = styled(Button)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  && {
    font-size: 0.9rem;
    text-transform: capitalize;
  }
`;
const TopPositionedButton = styled(PositionedButton)`
  margin: ${({ theme }) => theme.sizes.lvl2} 0;
  padding: 0px;
`;

const DropDownContainer = styled.div`
  text-align: left;
  margin-bottom: ${({ theme }) => theme.sizes.lvl3};

  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    margin-bottom: ${({ theme }) => theme.sizes.lvl4};
  `}

  ul {
    li {
      background: none;
      position: relative;
      font-size: ${({ theme }) => theme.font.sizes.sm};
      font-weight: 400;
      list-style: none;
      margin: 0;
      background: url(${IncludedBlueTickPath}) no-repeat left center;
      padding: ${({ theme }) =>
    `${theme.sizes.lvl1} ${theme.sizes.lvl1} ${theme.sizes.lvl1} ${theme.sizes.lvl6}`};
    }
  }
`;

const RotatingCaretIcon = styled(Icon)`
  margin-left: ${({ theme }) => theme.sizes.lvl2};
  transform: rotate(
    ${({ $dropDownOpen }) => ($dropDownOpen ? '180deg' : '0deg')}
  );
  transition: 0.3s transform;
`;

const ButtonLink = styled.a`
  margin-top: auto;
  text-decoration: none;
`;
const ButtonGatsbyLink = styled(Link)`
  margin-top: auto;
  text-decoration: none;
`;

const GiftCard = ({
  className,
  speakerName,
  title,
  subtitle,
  content,
  initiallyHideContent,
  image,
  colour,
  buttonColour,
}) => {
  const { isRegistered } = useUserContext();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const avatar = image.localFiles[0];

  return (
    <Container className={className} colour={colour}>
      {avatar && avatar.childImageSharp && (
        <StyledAvatarContainer>
          <GatsbyImage alt={`Gift ${title} image`} image={getImage(avatar)} />
        </StyledAvatarContainer>
      )}
      <Name>{speakerName}</Name>
      <TitlesContainer>
        <Title className="h4">{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
      </TitlesContainer>
      {content && (
        <>
          {initiallyHideContent && (
            <TopPositionedButton
              onClick={() => setDropDownOpen((oldState) => !oldState)}
              fluid
              icon={
                <RotatingCaretIcon
                  svg={caret}
                  $dropDownOpen={dropDownOpen}
                  width="14px"
                  fillColor="#000"
                />
              }
            >
              What you'll learn
            </TopPositionedButton>
          )}

          {(dropDownOpen || !initiallyHideContent) && (
            <DropDownContainer>
              <div
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            </DropDownContainer>
          )}
        </>
      )}
      {isRegistered ? (
        <ButtonGatsbyLink to="/thankyou" title="Link to thankyou gifts page">
          <PositionedButton fluid level={buttonColour}>
            Access
          </PositionedButton>
        </ButtonGatsbyLink>
      ) : (
        <ButtonLink href="#signup" title="Link to register form">
          <PositionedButton fluid icon="lock_open" level={buttonColour}>
            Register to unlock
          </PositionedButton>
        </ButtonLink>
      )}
    </Container>
  );
};

GiftCard.propTypes = {
  className: PropTypes.string,
  speakerName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  initiallyHideContent: PropTypes.bool,
  image: PropTypes.object,
  colour: PropTypes.string,
  buttonColour: PropTypes.string,
};

GiftCard.defaultProps = {
  className: '',
  speakerName: '',
  title: '',
  subtitle: '',
  content: '',
  image: null,
  colour: theme.colours.secondary,
  buttonColour: 'secondary',
};

export default GiftCard;
