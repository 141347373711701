import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Container from '../layout/Container';
import ExpertsAndTitle from '../components/ExpertsAndTitle';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const Wrapper = styled(Container)`
  position: relative;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => theme.sizes.lvl5} ${({ theme }) => theme.sizes.lvl2};

  ${({ theme }) => theme.breakpoint('phoneLarge')`
    padding: ${theme.sizes.lvl5} ${theme.sizes.lvl3};
  `}
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    padding: ${theme.sizes.lvl7} ${theme.sizes.lvl4};
  `}
  
  ${({ theme }) => theme.breakpoint('desktop')`
    padding: ${theme.sizes.lvl10} ${theme.sizes.lvl14};
  `}
    ${({ theme }) => theme.breakpoint('medium')`
    padding: ${theme.sizes.lvl10} ${theme.sizes.lvl16};
  `}
`;

const HomepageExpertsSection = () => {
  const theme = useContext(ThemeContext);
  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "Session" } }) {
        edges {
          node {
            data {
              Name
              DateOrder
              BonusSession
              Contentlist {
                childMarkdownRemark {
                  html
                }
              }
              Speaker {
                id
                data {
                  Name
                  WebsiteUri
                  ExpertOrder
                  Host
                  MainHost
                  Avatar {
                    localFiles {
                      childImageSharp {
                        fluid(maxWidth: 600) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `);

  const speakers =
    allAirtable?.edges
      ?.map((edge) => edge?.node)
      .filter(
        ({ data }) =>
          !!data.Speaker &&
          !data.Speaker[0].data.Host &&
          !data.Speaker[0].data.MainHost &&
          !data.BonusSession
      )
      .map(({ data }) => ({
        id: data.Speaker[0].id,
        data: {
          ...data.Speaker[0].data,
          Tagline: data.Name,
          Contentlist: data.Contentlist,
        },
      }))
      .sort(
        (speakerA, speakerB) =>
          speakerA.data.ExpertOrder - speakerB.data.ExpertOrder
      ) || [];

  return (
    <Background id="conferenceexperts">
      <Wrapper>
        <ExpertsAndTitle
          title="Conference Experts"
          titleColour={theme.colours.secondary}
          speakers={speakers}
          hideBuyButton
        />
      </Wrapper>
    </Background>
  );
};

export default HomepageExpertsSection;
