import React from 'react';
import { graphql } from 'gatsby';
import RegisteredLayout from '../layout/RegisteredLayout';
import Seo from '../components/Seo';

import AgendaDayExpertsSection from '../sections/agendaDayExperts';

import BuyNowCtaSection from '../sections/buyNowCta';
import YourHostSection from '../sections/yourHost';
import SponsorsSection from '../sections/sponsors';

const DayPage = ({ data }) => {
  return (
    <RegisteredLayout>
      <Seo title="Agenda" />
      <AgendaDayExpertsSection day={data} />
      <YourHostSection />
      <BuyNowCtaSection />
      <SponsorsSection />
    </RegisteredLayout>
  );
};

export const pageQuery = graphql`
  query DayByName($name: String!) {
    airtable(table: { eq: "Day" }, data: { Name: { eq: $name } }) {
      data {
        Name
        ShortName
        LongName
        Date
        Slug
        Session {
          id
          data {
            Name
            Slug
            DateOrder
            Category
            BonusSession
            Contentlist {
              childMarkdownRemark {
                html
              }
            }
            Speaker {
              id
              data {
                Name
                WebsiteUri
                Avatar {
                  localFiles {
                    childImageSharp {
                      fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default DayPage;
