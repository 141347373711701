import { parse } from 'query-string';
import { createRef } from 'react';

const affiliateRef = createRef({
  affiliate: '',
  isFb: false,
  isGoogle: false,
  isLeadMagnet: false,
});

// Affiliate url: https://fatiguesuperconference.com/?affid=JodiCohen
// FB url: https://www.fatiguesuperconference.com/?fid=fb

export const captureAffiliateIds = () => {
  const params = parse(window.location.search);
  affiliateRef.current = {
    affiliate: params.affiliate || params.affid || '',
    isFb: !!params.fid,
    isGoogle: !!params.gid,
    isLeadMagnet: !!window.location.pathname.includes('meditation'),
  };
  return affiliateRef.current
};

export const getIsFacebook = () => affiliateRef.current?.isFb || false;
export const getIsGoogle = () => affiliateRef.current?.isGoogle || false;
export const getIsLeadMagnet = () => affiliateRef.current?.isLeadMagnet || false;

const hashString = (string) =>
  string.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

export const trackRegistration = async (id, fn) => {
  if (typeof window !== 'undefined') {
    const isFb = getIsFacebook();
    if (typeof window.fbq === 'function' && isFb) {
      await window.fbq('track', 'Lead', {}, { eventID: hashString(id), fn, em: id });
    }
    if (typeof window.gtag === 'function') {
      await window.gtag('event', isFb ? 'Lead_fb' : 'Lead');
    }
  }
};

export const trackPurchase = (plan, id) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (typeof window.fbq === 'function' && getIsFacebook()) {
    window.fbq('track', 'Purchase', {}, { eventID: hashString(id) });
  }
};
