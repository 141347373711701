import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';

import Speaker from './Speaker/Speaker';
import Button from './Button';
import TitleSeperator from './TitleSeperator';
import theme from '../utils/theme';

const SpeakersWrapper = styled.div`
  overflow: auto;
  margin-top: ${({ theme }) => theme.sizes.lvl2};
  margin-bottom: ${({ theme }) => theme.sizes.lvl4};

  display: grid;
  grid-row-gap: ${({ theme }) => theme.sizes.lvl2};

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    margin-top: ${({ theme }) => theme.sizes.lvl4};

    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: ${({ theme }) => theme.sizes.lvl4};
    grid-column-gap: ${({ theme }) => theme.sizes.lvl4};
  `};
  ${({ theme }) => theme.breakpoint('desktop')`
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${({ theme }) => theme.sizes.lvl6};
  `};
`;

const Day = styled.h2`
  && {
    color: ${({ $colour }) => $colour};
    margin-bottom: ${({ theme }) => theme.sizes.lvl1};
    text-align: center;
    text-transform: uppercase;
  }
`;

const Description = styled.h2`
  && {
    text-align: center;
    max-width: 480px;
    width: 100%;
    margin: 0 auto ${({ theme }) => theme.sizes.lvl4};
    color: ${({ $colour }) => $colour};
  }
`;

const hashCode = (s) =>
  s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

const ExpertsAndTitle = ({
  className,
  speakers,
  title,
  description,
  day,
  colour,
  titleColour,
  buttonColour,
  hideVideoButton,
  hideBuyButton,
  hideWebsiteButton,
  date,
}) => {
  const [showAll, setShowAll] = useState(!isMobileOnly);
  const filteredSpeakers = showAll ? speakers : speakers.slice(0, 2);

  return (
    <div className={className}>
      {day && <Day $colour={titleColour || colour}>Day {day}</Day>}
      {description && (
        <Description $colour={titleColour || colour} className="h4">
          {description}
        </Description>
      )}
      <TitleSeperator text={title} colour={titleColour || colour} />
      <SpeakersWrapper $colour={buttonColour}>
        {filteredSpeakers.map((speaker) => {
          return (
            <Speaker
              key={`expertSpeaker_${speaker.id}_${hashCode(
                speaker.data.Tagline
              )}_${date}`}
              speaker={speaker.data}
              colour={colour}
              buttonColour={buttonColour}
              hideVideoButton={hideVideoButton}
              hideWebsiteButton={hideWebsiteButton}
              hideBuyButton={hideBuyButton}
              date={date}
            />
          );
        })}
      </SpeakersWrapper>
      {!showAll && (
        <Button
          onClick={() => setShowAll(true)}
          fluid
          level="secondary"
          icon="expand_more"
        >
          Show all speakers
        </Button>
      )}
    </div>
  );
};

ExpertsAndTitle.propTypes = {
  className: PropTypes.string,
  speakers: PropTypes.array,
  title: PropTypes.string,
  titleColour: PropTypes.string,
  colour: PropTypes.string,
  buttonColour: PropTypes.string,
  withVideoButton: PropTypes.bool,
  hideBuyButton: PropTypes.bool,
  hideWebsiteButton: PropTypes.bool,
  date: PropTypes.string,
};

ExpertsAndTitle.defaultProps = {
  className: '',
  speakers: [],
  title: '',
  titleColour: undefined,
  colour: theme.colours.secondary,
  buttonColour: 'secondary',
  withVideoButton: false,
  hideBuyButton: false,
  hideWebsiteButton: false,
  date: null,
};

export default ExpertsAndTitle;
