import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import ReactPlayer from 'react-player';

import Container from '../../layout/Container';
import Button from '../../components/Button';

const StyledBackgroundImage = styled(BackgroundImage)`
  background-attachment: fixed;
`;

const BackgroundSection = ({ className, children, data }) => {
  const backgroundFluidImageStack = [
    data.desktop.childImageSharp.fluid,
    `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1))`,
  ].reverse();

  return (
    <StyledBackgroundImage
      Tag="section"
      className={className}
      fluid={backgroundFluidImageStack}
      backgroundColor={`#040e18`}
    >
      {children}
    </StyledBackgroundImage>
  );
};

const ContentContainer = styled(Container)`
  margin-top: -${({ theme }) => theme.sizes.lvl10};
  background-image: linear-gradient(transparent 0%, #232221 18%);
  ${({ theme }) => theme.breakpoint('phoneLarge')`
    margin-top: -${({ theme }) => theme.sizes.lvl20};
    background-image: linear-gradient(transparent 0%, #232221 37%);
  `}
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    margin: 0 auto;
    background-image: none;
  `}
`;

const VideoContainer = styled.div`
  z-index: -1;
  overflow: hidden;

  position: relative;
  padding-top: 56.25%;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    position: absolute;
    left: 0;
    right: 0;
`}
`;

const Shadow = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

const StyledBackgroundSection = styled(BackgroundSection)`
  position: relative;
  width: 100%;
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  position: relative;
`;
const InnerCol = styled.div`
  color: ${({ theme }) => theme.colours.white};
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    flex-direction: column;
    padding: ${({ theme }) => theme.sizes.lvl8} 0;
    width: 100%;
    max-width: 360px;
    text-align: left;
  `}
`;

const Title = styled.h1`
  font-weight: 600;
`;
const PositionedTitle = styled(Title)`
  margin-top: ${({ theme }) => theme.sizes.lvl5};
`;

const PositionedButton = styled(Button)`
  margin: 0 auto ${({ theme }) => theme.sizes.lvl4};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    height: ${({ theme }) => theme.sizes.lvl10};
    margin: ${({ theme }) => theme.sizes.lvl6} 0 0 0;
  `}
`;

const SalesTrailerSection = () => {
  const [volume, setVolume] = useState(0);
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "flower_hands_bg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const playerRef = useRef(null);

  const onVideoPlay = () => {
    setVolume(1);
    if (
      document &&
      (document.fullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.documentElement.webkitRequestFullScreen)
    ) {
      const player = playerRef.current.getInternalPlayer();
      document.onfullscreenchange = (_event) => {
        if (!document.fullscreenElement) {
          setVolume(0);
        }
      };
      player.requestFullscreen();
      playerRef.current.seekTo(0);
    }
  };

  return (
    <StyledBackgroundSection data={data}>
      <VideoContainer>
        <ReactPlayer
          className="react-player"
          ref={playerRef}
          controls={!!volume}
          allowFullScreen
          volume={volume}
          loop={!volume}
          playing
          url="https://player.vimeo.com/external/651135946.hd.mp4?s=82b05561cafdbba21e8441a3275fa5dd168c12d2&profile_id=175"
          width="100%"
          height="100%"
        />
        <Shadow />
      </VideoContainer>
      <ContentContainer>
        <InnerCol>
          <div>
            <Title className="h3">90+ hours of content</Title>
            <p>
              Expert interviews, real-life therapy footage, guided meditations
              and yoga sessions.
            </p>

            <PositionedTitle className="h3">
              70+ feature interviews
            </PositionedTitle>
            <p>Hear from internationally trusted trauma experts.</p>
          </div>
          <PositionedButton icon="play_arrow" onClick={onVideoPlay}>
            Watch video trailer
          </PositionedButton>
        </InnerCol>
      </ContentContainer>
    </StyledBackgroundSection>
  );
};

export default SalesTrailerSection;
