import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useSiteMetaContext } from '../context/SiteMetaContext';

import Container from '../layout/Container';
import PaidContent from '../components/PaidContent';

const Background = styled.div`
  background-color: ${({ theme }) => theme.colours.backgroundAlt};
`;

const Wrapper = styled(Container)`
  color: ${({ theme }) => theme.colours.white};
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    flex-direction: row;
  `}
`;
const StyledLink = styled(Link)`
  text-decoration: none;
`;
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  border-radius: 100px;
  text-transform: uppercase;
  padding: ${({ theme }) => theme.sizes.lvl1} ${({ theme }) => theme.sizes.lvl4};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ theme }) => theme.colours.primary};
  color: ${({ theme }) => theme.colours.white};
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: ${({ disabled }) =>
      disabled
        ? '0px 8px 15px rgba(0, 0, 0, 0.2)'
        : '0px 8px 15px rgba(0, 0, 0, 0.4)'};
  }
`;

const ButtonTitle = styled.p`
  font-size: ${({ theme }) => theme.font.sizes.md};
  color: ${({ theme }) => theme.colours.white};
  width: max-content;

  ${({ theme }) => theme.breakpoint('medium')`
  font-size: ${({ theme }) => theme.font.sizes.lg};
  `}
`;

const Icon = styled.span`
  margin-right: ${({ theme }) => theme.sizes.lvl3};
  font-size: ${({ theme }) => theme.font.sizes.xxxl};

  ${({ theme }) => theme.breakpoint('medium')`
  font-size: ${({ theme }) => theme.font.sizes.xxxxl};
  `}
`;

const Content = styled.p`
  margin: 0;
`;
const ButtonContainer = styled.div`
  margin: 0 0 ${({ theme }) => theme.sizes.lvl3};
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    margin: 0 ${({ theme }) => theme.sizes.lvl4} 0 0;
  `}
`;
const Disclaimer = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.sizes.xs};
  margin-top: ${({ theme }) => theme.sizes.lvl1};
`;

const BuyNowCtaSection = () => {
  const {
    title,
    hasPurchaseStarted,
    purchaseStartTimeFormatted,
  } = useSiteMetaContext();

  return (
    <PaidContent
      notPaid={
        <Background>
          <Wrapper>
            <ButtonContainer>
              <StyledLink to={hasPurchaseStarted ? '/sales' : '#'}>
                <Button disabled={!hasPurchaseStarted}>
                  <Icon className="material-icons">shopping_cart</Icon>
                  <ButtonTitle>buy now</ButtonTitle>
                </Button>
              </StyledLink>
              <Disclaimer>* purchase lifetime access</Disclaimer>
            </ButtonContainer>

            <div>
              <h1 className="h3 strong">Buy ALL The Conference Recordings!</h1>
              <Content>
                {hasPurchaseStarted
                  ? `Don't miss a minute of the conference content; for a one-time
                payment you can own all of the ${title}
                recordings.`
                  : `Available from ${purchaseStartTimeFormatted}`}
              </Content>
            </div>
          </Wrapper>
        </Background>
      }
    >
      <></>
    </PaidContent>
  );
};

export default BuyNowCtaSection;
