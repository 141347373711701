import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

import Container from '../layout/Container';

const Inner = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colours.white};
`;

const ListContainer = styled.ul`
  li {
    margin-bottom: ${({ theme }) => theme.sizes.lvl1};
    font-size ${({ theme }) => theme.font.sizes.md};
    
    ${({ theme }) => theme.breakpoint('desktop')`
        font-size ${({ theme }) => theme.font.sizes.lg};`}
  }

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    column-count: 2;
    column-gap: ${({ theme }) => theme.sizes.lvl8};
    li {
      margin-bottom: ${({ theme }) => theme.sizes.lvl2};
    }
  `}
`;
const Puzzle = styled.div`
  position: absolute;
  width: ${({ theme }) => theme.sizes.lvl4};
  top: -${({ theme }) => theme.sizes.lvl1};
  right: -${({ theme }) => theme.sizes.lvl4};
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    width: ${({ theme }) => theme.sizes.lvl5};
    top: -${({ theme }) => theme.sizes.lvl2};
    right: -${({ theme }) => theme.sizes.lvl6};
  `}
`;

const TitlePuzzleWrap = styled.div`
  position: relative;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-attachment: fixed;
`;

const BackgroundSection = ({ data, className, children }) => {
  const backgroundFluidImageStack = [
    data.desktop.childImageSharp.fluid,
    `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1))`,
  ].reverse();

  return (
    <StyledBackgroundImage
      Tag="section"
      className={className}
      fluid={backgroundFluidImageStack}
      backgroundColor={`#040e18`}
    >
      {children}
    </StyledBackgroundImage>
  );
};

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;

  padding-top: ${({ theme }) => theme.sizes.lvl10};

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    padding-top: 0;
  `}
`;

const ConferenceContentSection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "conference_content_bg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        puzzle: file(relativePath: { eq: "puzzle_colour.png" }) {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  return (
    <StyledBackgroundSection data={data}>
      <Inner>
        <TitlePuzzleWrap>
          <h1 className="h2">
            Our
            <span> Experts </span>
            will cover:
            <Puzzle>
              <Img fluid={data.puzzle.childImageSharp.fluid}></Img>
            </Puzzle>
          </h1>
        </TitlePuzzleWrap>

        <ListContainer>
          <li>Healing our collective trauma</li>
          <li>Understanding and healing relational trauma</li>
          <li>The relationship between trauma, substance use, and addiction</li>
          <li>Recovering from narcissistic abuse</li>
          <li>Stress and burnout as a symptom of trauma</li>
          <li>The future of psychedelics for healing trauma</li>
          <li>Hip hop as a form of therapy</li>
          <li>Navigating the journey of grief</li>
          <li>The power of functional medicine for healing</li>
          <li>Trauma as a lens to understand cultural mistrust</li>
          <li>And much more...</li>
        </ListContainer>
      </Inner>
    </StyledBackgroundSection>
  );
};

export default ConferenceContentSection;
