import React from 'react';
import styled, { keyframes } from 'styled-components';

const ldsDualRing = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.sizes.lvl2} 0;
  text-align: center;

  .lds-dual-ring {
    display: inline-block;
    width: ${({ $size }) => ($size ? $size : '64')}px;
    height: ${({ $size }) => ($size ? $size : '64')}px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: ${({ $size }) => ($size ? `${$size - $size / 5}px` : '52px')};
    height: ${({ $size }) => ($size ? `${$size - $size / 5}px` : '52px')};
    border-radius: 50%;
    border: ${({ $size }) => ($size ? `${$size / 10}px` : '6px')} solid
      ${({ theme, $color }) => $color || theme.colours.primary};
    border-color: ${({ theme, $color }) => $color || theme.colours.primary}
      transparent ${({ theme, $color }) => $color || theme.colours.primary}
      transparent;
    animation: ${ldsDualRing} 1.2s linear infinite;
  }
`;

const Loader = ({ className, size, color }) => {
  return (
    <Wrapper className={className} $size={size} $color={color}>
      <div className="lds-dual-ring"></div>
    </Wrapper>
  );
};

export default Loader;
