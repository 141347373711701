import React from 'react';
import styled from 'styled-components';

import Container from '../layout/Container';
import SigninForm from '../components/SigninForm';

const Background = styled.div`
  position: relative;
  background: repeating-linear-gradient(
    ${({ theme }) => theme.colours.white},
    ${({ theme }) => theme.colours.white} 35%,
    ${({ theme }) => theme.colours.backgroundPaper} 35.1%,
    ${({ theme }) => theme.colours.backgroundPaper} 100%
  );
`;

const Inner = styled(Container)`
  padding-top: 0px;
`;

const FormWrapper = styled.div`
  background-color: ${({ theme }) => theme.colours.backgroundAlt};
  padding: ${({ theme }) => theme.sizes.lvl5} ${({ theme }) => theme.sizes.lvl3};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
     padding: ${({ theme }) => theme.sizes.lvl5} ${({ theme }) =>
    theme.sizes.lvl8};

  `}
`;

const Title = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.colours.white};
`;

const LoginFormSection = () => {
  return (
    <Background>
      <Inner>
        <FormWrapper>
          <Title className="h2">Login Here</Title>
          <SigninForm />
        </FormWrapper>
      </Inner>
    </Background>
  );
};

export default LoginFormSection;
