import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Container from '../layout/Container';
import RegisterForm from '../components/RegisterForm';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundAlt};
`;

const Inner = styled(Container)`
  text-align: center;
  color: ${({ theme }) => theme.colours.white};
  position: relative;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleWrap = styled.div`
  display: block;
  margin: 0 auto;
  position: relative;
  margin-top: ${({ theme }) => theme.sizes.lvl8};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    margin-top: ${({ theme }) => theme.sizes.lvl6};
  `}
`;

const Puzzle = styled.div`
  width: ${({ theme }) => theme.sizes.lvl4};
  position: absolute;
  top: -${({ theme }) => theme.sizes.lvl3};
  right: -${({ theme }) => theme.sizes.lvl5};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    width:  ${({ theme }) => theme.sizes.lvl5};
    right: -${({ theme }) => theme.sizes.lvl6};
  `}
`;

const Subtitle = styled.h2`
  && {
    margin-bottom: ${({ theme }) => theme.sizes.lvl3};
  }
`;

const RegistrationRepeatedSection = ({ block }) => {
  const { site, puzzle } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        puzzle: file(relativePath: { eq: "puzzle_colour.png" }) {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const conferenceTitle = site?.siteMetadata?.title;

  return (
    <Background>
      <Inner>
        <TitleWrap>
          <h1 className="h2">
            Register <span>NOW</span>
          </h1>
          <Puzzle>
            <Img fluid={puzzle.childImageSharp.fluid}></Img>
          </Puzzle>
        </TitleWrap>
        <Subtitle className="h4">
          And join The {conferenceTitle} for free!
        </Subtitle>
        <RegisterForm />
      </Inner>
    </Background>
  );
};

export default RegistrationRepeatedSection;
