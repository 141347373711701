import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Container from '../layout/Container';
import LeftImageCard from '../components/LeftImageCard';

const Title = styled.h1`
  && {
    color: ${({ theme }) => theme.colours.secondary};
    margin-bottom: ${({ theme }) => theme.sizes.lvl1};
  }
`;

const Subtitle = styled.h3`
  && {
    margin-top: 0;
    color: ${({ theme }) => theme.colours.secondary};
    text-align: left;
    padding-bottom: ${({ theme }) => theme.sizes.lvl4};
    ${({ theme }) => theme.breakpoint('tabletPortrait')`
      padding-bottom:${({ theme }) => theme.sizes.lvl2};
    `}
  }
`;

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.white};
`;

const Wrapper = styled(Container)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  padding-top: ${({ theme }) => theme.sizes.lvl10};
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    padding-top: ${({ theme }) => theme.sizes.lvl8};
  `}
`;

const GiftCard = styled(LeftImageCard)`
  position: relative;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  &:last-child {
    margin-bottom: -${({ theme }) => theme.sizes.lvl8};
    z-index: 9;
  }
`;

const GiftCardIndex = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -${({ theme }) => theme.sizes.lvl4};
  margin: auto 0;
  background-color: ${({ theme }) => theme.colours.white};
  width: ${({ theme }) => theme.sizes.lvl8};
  height: ${({ theme }) => theme.sizes.lvl8};
  border-radius: ${({ theme }) => theme.sizes.lvl8};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    left: -${({ theme }) => theme.sizes.lvl4};
    top: 0;
    bottom: 0;
    transform: none;
  `}
`;

const Index = styled.h3`
  font-family: ${({ theme }) => theme.font.families.title} !important;
  font-weight: 400;
  color: ${({ theme }) => theme.colours.primary};
  font-size: ${({ theme }) => theme.sizes.lvl3};
  line-height: ${({ theme }) => theme.sizes.lvl3};
  padding: 0;
  margin: 0;
  display: flex;
`;
const GiftsWrapper = styled.div``;

const GiftsSection = () => {
  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "Session" }, data: { Gift: { eq: true } } }
        sort: { fields: data___GiftOrder }
      ) {
        edges {
          node {
            data {
              Name
              Tagline {
                childMarkdownRemark {
                  html
                }
              }
              Contentlist {
                childMarkdownRemark {
                  html
                }
              }
              Speaker {
                id
                data {
                  WebsiteUri
                }
              }
              GiftImage {
                localFiles {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `);

  const gifts = allAirtable?.edges?.map((edge) => edge?.node) || [];

  return (
    <Background>
      <Wrapper>
        <Title className="h2">
          <span>Free bonus gifts</span>
        </Title>
        <Subtitle className="h4">
          When you register today, you'll gain <span>INSTANT</span> access to
          the following free gifts
        </Subtitle>
        <GiftsWrapper>
          {gifts.map((gift, index) => {
            return (
              <GiftCard
                key={`gift_${gift.id}`}
                title={gift.data.Name}
                buttonLink={gift.data?.Speaker[0]?.data?.WebsiteUri}
                buttonLabel="Visit Speaker's Website"
                imageFluid={
                  gift.data?.GiftImage?.localFiles[0]?.childImageSharp?.fluid
                }
              >
                {gift.data?.Tagline?.childMarkdownRemark?.html && (
                  <div
                    className="p"
                    dangerouslySetInnerHTML={{
                      __html: gift.data?.Tagline?.childMarkdownRemark.html,
                    }}
                  />
                )}
                <p style={{ marginBottom: '0.5rem' }}>You will learn:</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: gift.data?.Contentlist?.childMarkdownRemark.html,
                  }}
                />
                <GiftCardIndex>
                  <Index>{index + 1}</Index>
                </GiftCardIndex>
              </GiftCard>
            );
          })}
        </GiftsWrapper>
      </Wrapper>
    </Background>
  );
};

export default GiftsSection;
