import styled from 'styled-components';

export const ScrollLink = styled.div`
  width: 1px; /* '0' will not work for Opera */
  display: hidden;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    padding-top: ${({ theme }) => theme.sizes.lvl20};
    margin-top: -${({ theme }) => theme.sizes.lvl20};
  `}
`;

export default ScrollLink;
