export const plans = [
  {
    id: 'online',
    name: 'Online Access Only',
    oldPrice: 159,
    price: 99,
    requiresAddress: false,
    soldOut: false,
  },
  {
    id: 'onlineflash',
    name: 'Online Access + Flash Drive',
    oldPrice: 199,
    price: 139,
    requiresAddress: true,
    soldOut: true,
  },
  {
    id: 'flash',
    name: 'Flash Drive Only',
    oldPrice: 199,
    price: 139,
    requiresAddress: true,
    soldOut: true,
  },
];

export default plans;
