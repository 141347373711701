import React from 'react';
import styled from 'styled-components';

import Container from '../layout/Container';
import ExpertsAndTitle from '../components/ExpertsAndTitle';
import { dayMeta } from '../constants/dayMeta';
import PropTypes from 'prop-types';

const ExpertsBackground = styled.div`
  background-color: ${({ $hasBackground, theme }) =>
    $hasBackground ? theme.colours.backgroundPaper : '#fff'};
`;

const BonusExpertsAndTitle = styled(ExpertsAndTitle)`
  margin-top: ${({ theme }) => theme.sizes.lvl4};
  ${({ theme }) => theme.breakpoint('desktop')`
    margin-top: 0;
  `}
`;

const AgendaDayExpertsSection = (day) => {
  let data = day.day.airtable.data;
  let bonusSpeakers = data.Session.map(({ data: sessionData }) => sessionData)
    .filter(({ BonusSession }) => BonusSession)
    .sort((sessionA, sessionB) => sessionA.DateOrder - sessionB.DateOrder)
    .map((data) => ({
      id: data.Speaker[0].id,
      data: {
        ...data.Speaker[0].data,
        Tagline: data.Name,
        Category: data.Category,
        Contentlist: data.Contentlist,
        BonusSession: data.BonusSession,
        VideoSlug: data.Slug,
      },
    }));

  let normalSpeakers = data.Session.map(({ data: sessionData }) => sessionData)
    .filter(({ BonusSession }) => !BonusSession)
    .sort((sessionA, sessionB) => sessionA.DateOrder - sessionB.DateOrder)
    .map((data) => {
      return {
        id: data.Speaker[0].id,
        data: {
          ...data.Speaker[0].data,
          Tagline: data.Name,
          Category: data.Category,
          Contentlist: data.Contentlist,
          VideoSlug: data.Slug,
        },
      };
    });

  let index = parseInt(data.Name.replace('Day ', '')) - 1;

  let date = data.Date;

  return (
    <>
      <ExpertsBackground
        key={`experts_${data.Slug}`}
        $hasBackground={index % 2}
      >
        <Container>
          <ExpertsAndTitle
            title={data.ShortName}
            description={data.LongName}
            day={index + 1}
            speakers={normalSpeakers}
            colour={dayMeta[data.Date].colour}
            buttonColour={dayMeta[data.Date].buttonColour}
            date={date}
          />
          {bonusSpeakers && bonusSpeakers.length > 0 && (
            <BonusExpertsAndTitle
              title="Bonus Sessions"
              speakers={bonusSpeakers}
              colour={dayMeta[data.Date].colour}
              buttonColour={dayMeta[data.Date].buttonColour}
              date={date}
              hideWebsiteButton
            />
          )}
        </Container>
      </ExpertsBackground>
    </>
  );
};

AgendaDayExpertsSection.propTypes = {
  day: PropTypes.object,
};

AgendaDayExpertsSection.defaultProps = {
  day: null,
};

export default AgendaDayExpertsSection;
