import React, { createContext, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { format, isPast } from 'date-fns';
import { getIsFacebook, getIsGoogle, getIsLeadMagnet } from '../services/affiliate';
import { formatConferenceDateString } from '../utils/dates';

const SiteMetaContext = createContext({
  title: '',
  uri: '',
  logoFluidObj: undefined,
  logoColouredFluidObj: undefined,
  conferenceStartTime: undefined,
  conferenceEndTime: undefined,
  replayStartTime: undefined,
  purchaseStartTimeFormatted: undefined,
  hasPurchaseStarted: undefined,
  hasConferenceStarted: undefined,
  hasConferenceEnded: undefined,
  hasReplayStarted: undefined,
  hasReplayEnded: undefined,
  isConferenceLive: undefined,
  isReplayConferenceLive: undefined,
  conferenceDateString: undefined,
  conferenceDateStringWithDays: undefined,
  infusionsoftRegistrationFormId: '',
  infusionsoftFacebookRegistrationFormId: '',
  infusionsoftLeadMagnetRegistrationFormId: '',
  landingVideoLoop: '',
  landingVideo: '',
});

const SiteMetaContextProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          uri
          conferenceInformation {
            infusionsoftRegistrationFormId
            infusionsoftFacebookRegistrationFormId
            infusionsoftGoogleRegistrationFormId
            infusionsoftLeadMagnetRegistrationFormId
            landingVideoLoop
            landingVideo
            purchaseStarttime {
              year
              month
              day
              hour
            }
            startTime {
              year
              month
              day
              hour
            }
            endTime {
              year
              month
              day
              hour
            }
            replayStarttime {
              year
              month
              day
              hour
            }
            replayEndtime {
              year
              month
              day
              hour
            }
            replayWeekendEnabled
          }
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logocoloured: file(relativePath: { eq: "logo_coloured.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const {
    startTime,
    endTime,
    replayStarttime,
    replayEndtime,
    purchaseStarttime,
    replayWeekendEnabled,
    infusionsoftRegistrationFormId,
    infusionsoftGoogleRegistrationFormId,
    infusionsoftFacebookRegistrationFormId,
    infusionsoftLeadMagnetRegistrationFormId,
    landingVideoLoop,
    landingVideo,
  } = data.site.siteMetadata.conferenceInformation;

  const purchaseStartTime = new Date(
    purchaseStarttime.year,
    purchaseStarttime.month,
    purchaseStarttime.day,
    purchaseStarttime.hour
  );
  const conferenceStartTime = new Date(
    startTime.year,
    startTime.month,
    startTime.day,
    startTime.hour
  );
  const conferenceEndTime = new Date(
    endTime.year,
    endTime.month,
    endTime.day,
    endTime.hour
  );
  const replayStartTime = new Date(
    replayStarttime.year,
    replayStarttime.month,
    replayStarttime.day,
    replayStarttime.hour
  );
  const replayEndTime = new Date(
    replayEndtime.year,
    replayEndtime.month,
    replayEndtime.day,
    replayEndtime.hour
  );

  const purchaseStartTimeFormatted = format(purchaseStartTime, 'do MMM yyyy');

  const hasConferenceStarted = isPast(conferenceStartTime);
  const hasPurchaseStarted = isPast(purchaseStartTime);
  const hasConferenceEnded = isPast(conferenceEndTime);
  const hasReplayStarted = isPast(replayStartTime);
  const hasReplayEnded = isPast(replayEndTime);
  const isConferenceLive =
    (hasConferenceStarted && !hasConferenceEnded) ||
    (hasReplayStarted && !hasReplayEnded);

  const conferenceDateString = replayWeekendEnabled
    ? formatConferenceDateString(
      startTime,
      endTime,
      replayStarttime,
      replayEndtime
    )
    : formatConferenceDateString(startTime, endTime);

  const conferenceDateStringWithDays = replayWeekendEnabled
    ? formatConferenceDateString(
      startTime,
      endTime,
      replayStarttime,
      replayEndtime,
      true
    )
    : formatConferenceDateString(
      startTime,
      endTime,
      undefined,
      undefined,
      true
    );
    const isReplayConferenceLive = (hasReplayStarted && !hasReplayEnded);

  return (
    <SiteMetaContext.Provider
      value={{
        title: data?.site?.siteMetadata?.title,
        uri: data?.site?.siteMetadata?.uri,
        isFb: getIsFacebook(),
        isGoogle: getIsGoogle(),
        isLeadMagnet: getIsLeadMagnet(),
        logoFluidObj: data?.logo?.childImageSharp?.fluid,
        logoColouredFluidObj: data?.logocoloured?.childImageSharp?.fluid,
        conferenceStartTime,
        conferenceEndTime,
        replayStartTime,
        purchaseStartTimeFormatted,
        hasPurchaseStarted,
        hasConferenceStarted,
        hasConferenceEnded,
        hasReplayStarted,
        hasReplayEnded,
        isConferenceLive,
        replayWeekendEnabled,
        isReplayConferenceLive,
        conferenceDateString,
        conferenceDateStringWithDays,
        infusionsoftRegistrationFormId,
        infusionsoftFacebookRegistrationFormId,
        infusionsoftGoogleRegistrationFormId,
        infusionsoftLeadMagnetRegistrationFormId,
        landingVideoLoop,
        landingVideo,
      }}
    >
      {children}
    </SiteMetaContext.Provider>
  );
};

const useSiteMetaContext = () => {
  const context = useContext(SiteMetaContext);
  if (context === undefined) {
    throw new Error(
      'useSiteMetaContext must be used within a SiteContextProvider'
    );
  }
  return context;
};

export default SiteMetaContextProvider;
export { SiteMetaContextProvider, useSiteMetaContext };
