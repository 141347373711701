import theme from '../utils/theme';

export const dayMeta = {
  '2022-04-25': {
    colour: theme.colours.secondary,
    buttonColour: 'secondary',
  },
  '2022-04-26': {
    colour: theme.colours.secondary,
    buttonColour: 'secondary',
  },
  '2022-04-27': {
    colour: theme.colours.secondary,
    buttonColour: 'secondary',
  },
  '2022-04-28': {
    colour: theme.colours.secondary,
    buttonColour: 'secondary',
  },
  '2022-04-29': {
    colour: theme.colours.secondary,
    buttonColour: 'secondary',
  },
  '2022-04-30': {
    colour: theme.colours.secondary,
    buttonColour: 'secondary',
  },
  '2022-05-01': {
    colour: theme.colours.secondary,
    buttonColour: 'secondary',
  },
};
