import React, { useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import ItemsCarousel from 'react-items-carousel';
import { intervalToDuration, formatDuration } from 'date-fns';

import GiftSvg from '../../images/gift.inline.svg';
import Container from '../../layout/Container';
import GiftCard from '../../components/Speaker/GiftCard';

const Background = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const Inner = styled(Container)`
  text-align: center;
`;

const SizedGiftSvg = styled(GiftSvg)`
  margin: 0 auto ${({ theme }) => theme.sizes.lvl2};
  width: ${({ theme }) => theme.sizes.lvl9};
`;

const Title = styled.h1`
  && {
    font-weight: 700;
    line-height: 1.1;
  }
`;

const Subtitle = styled.h2`
  && {
    margin-bottom: ${({ theme }) => theme.sizes.lvl6};
  }
`;

const GiftCardsDesktop = styled.div`
  display: none;
  grid-column-gap: ${({ theme }) => theme.sizes.lvl2};
  grid-row-gap: ${({ theme }) => theme.sizes.lvl2};

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    grid-template-columns: repeat(3, 1fr);
  `};
`;

const MobileOnly = styled.div`
  display: block;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    display: none;
  `};
`;
const CarouselDotContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizes.lvl2};
`;
const Dot = styled.span`
  font-size: ${({ theme }) => theme.font.sizes.xs};
  margin: 0 ${({ theme }) => theme.sizes.lvl1};
  color: ${({ $active, theme }) =>
    $active ? theme.colours.secondary : theme.colours.textDisabled};
`;

const LandingStickyRegistrationSection = () => {
  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "Session" }, data: { Gift: { eq: true } } }
        sort: { fields: data___GiftOrder }
      ) {
        edges {
          node {
            data {
              Name
              Contentlist {
                childMarkdownRemark {
                  html
                }
              }
              Tagline {
                childMarkdownRemark {
                  html
                }
              }
              Speaker {
                id
                data {
                  Name
                }
              }
              Duration
              GiftImage {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 200)
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const gifts = allAirtable?.edges?.map((edge) => edge?.node);
  const giftElements = gifts.map(({ data }) => {
    return (
      <GiftCard
        key={`gift_${data.Name}`}
        speakerName={data.Speaker[0].data.Name}
        title={data.Name}
        subtitle={formatDuration(
          intervalToDuration({ start: 0, end: data.Duration * 1000 }),
          { format: ['hours', 'minutes'] })}
        content={data.Contentlist.childMarkdownRemark.html}
        image={data.GiftImage}
      />
    );
  });

  return (
    <Background id="freegifts">
      <Inner>
        <SizedGiftSvg />
        <Title className="h2">Instant Access To Free Gifts</Title>
        <Subtitle className="h4">
          Instant access to the following gifts when you register today
        </Subtitle>
        <MobileOnly>
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={1}
            gutter={8}
          >
            {giftElements}
          </ItemsCarousel>
          <CarouselDotContainer>
            {[...Array(gifts.length)].map((e, i) => (
              <Dot
                className="material-icons"
                key={`dot_${i}`}
                $active={activeItemIndex === i}
              >
                circle
              </Dot>
            ))}
          </CarouselDotContainer>
        </MobileOnly>
        <GiftCardsDesktop>{giftElements}</GiftCardsDesktop>
      </Inner>
    </Background>
  );
};

export default LandingStickyRegistrationSection;
