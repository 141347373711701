import React from 'react';
import PropTypes from 'prop-types';

import ThemeProvider from '../components/ThemeProvider';
import GlobalStyle from '../components/GlobalStyle';
import Footer from '../components/Footer';

const Layout = ({ children }) => {
  return (
    <ThemeProvider>
      <GlobalStyle />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

Layout.defaultProps = {
  children: undefined,
};

export default Layout;
