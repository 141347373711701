import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Loader from './Loader';
import theme from '../utils/theme';

const StyledButton = styled.button`
  font-family: ${({ theme }) => theme.font.families.body};
  font-weight: 700;
  font-size ${({ theme }) => theme.font.sizes.lg};

  background: ${({ $backgroundColour }) => $backgroundColour};
  width: ${({ $fluid }) => ($fluid ? '100%' : 'auto')};
  color: ${({ $textColour }) => $textColour};
  height: ${({ $height }) =>
    $height === 'auto' ? theme.sizes.formControlHeight : $height};
  padding: ${({ theme }) => theme.sizes.lvl0} ${({ theme }) =>
  theme.sizes.lvl6};
  position: relative;
  border: ${({ $outlineColour }) => `3px ${$outlineColour} solid`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.5s;
    border-radius: ${({ theme }) => theme.sizes.borderRadius};
  }
  :hover,
  :focus {
    outline: 0;
    &:after {
      opacity: ${({ disabled }) => (disabled ? 0 : 1)};
    }
  }
`;

const MaterialIcon = styled.span`
  margin-right: ${({ theme }) => theme.sizes.lvl1};
`;

const Button = ({
  children,
  className,
  onClick,
  disabled,
  variant,
  icon,
  loading,
  height,
  fluid,
  level,
  outlineColour,
  textColour,
  backgroundColour,
  ...otherProps
}) => {
  const bgColour =
    backgroundColour || theme.buttonColours[level].background[variant];
  const outColour =
    outlineColour || theme.buttonColours[level].outline[variant];
  const txtColour = textColour || theme.buttonColours[level].text[variant];

  return (
    <StyledButton
      className={className}
      onClick={onClick}
      disabled={disabled}
      $backgroundColour={bgColour}
      $outlineColour={outColour}
      $textColour={txtColour}
      $variant={variant}
      $fluid={fluid}
      $height={height}
      {...otherProps}
    >
      {icon && (
        <MaterialIcon className="material-icons" style={{ color: txtColour }}>
          {icon}
        </MaterialIcon>
      )}

      {children}

      {loading && <Loader size={32} color={txtColour} />}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  variant: PropTypes.oneOf(['filled', 'filledDarker', 'outline']),
  height: PropTypes.string,
  level: PropTypes.oneOf(['primary', 'black', 'secondary', 'white']),
  icon: PropTypes.node,
  loading: PropTypes.bool,
  outlineColour: PropTypes.string,
  textColour: PropTypes.string,
  backgroundColour: PropTypes.string,
};

Button.defaultProps = {
  children: null,
  className: '',
  onClick: null,
  disabled: false,
  fluid: false,
  variant: 'filled',
  height: 'auto',
  level: 'primary',
  icon: null,
  loading: false,
  outlineColour: '',
  textColour: '',
  backgroundColour: '',
};

export { Button };
export default Button;
