import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  flex: 1;
  border-top: 3px solid ${({ $colour }) => $colour};
`;

const TitleText = styled.h1`
  && {
    text-transform: capitalize;
    color: ${({ $colour }) => $colour};
    margin: 0 ${({ theme }) => theme.sizes.lvl1};

    ${({ theme }) => theme.breakpoint('phoneLarge')`
      margin: 0 ${({ theme }) => theme.sizes.lvl2};
    `}
    ${({ theme }) => theme.breakpoint('tabletPortrait')`
      margin: 0 ${({ theme }) => theme.sizes.lvl3};
    `}
  }
`;

const TitleSeperator = ({ className, text, colour }) => {
  return (
    <TitleWrap className={className}>
      <Line $colour={colour} />
      {text && (
        <TitleText className="h3 strong" $colour={colour}>
          {text}
        </TitleText>
      )}
      <Line $colour={colour} />
    </TitleWrap>
  );
};

TitleSeperator.propTypes = {
  className: PropTypes.string,
  colour: PropTypes.string,
  text: PropTypes.string,
};

TitleSeperator.defaultProps = {
  className: '',
  colour: 'rgb(221, 89, 57)',
  text: '',
};

export default TitleSeperator;
